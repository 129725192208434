import * as FileSaver from "file-saver";
import firebase from "firebase/app";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { IoAttach, IoDuplicate, IoEye, IoPencil, IoPeople, IoTrash } from "react-icons/io5";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Modal from "react-modal";
import { Link, useHistory } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import styled from "styled-components";
import * as XLSX from "xlsx";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},

	overlay: {
		backgroundColor: "rgba(0,0,0,.7)",
	},
};

const AdminScreen = () => {
	const [data, setData] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [modal, setModal] = useState(false);
	const [duplicating, setDuplicating] = useState(false);

	const inputRef = useRef(null);

	const hisotry = useHistory();

	useEffect(() => {
		const subs = firebase
			.firestore()
			.collection("forms")
			.orderBy("order", "asc")
			.onSnapshot(
				(query) => {
					const d = query.docs.map((doc) => ({ ...doc.data(), id: doc.id, ref: doc.ref }));
					setData(d);
					setFiltered(d);
					setLoading(false);
				},
				(err) => {
					console.log(err);
					setError(true);
					setLoading(false);
				}
			);
		// firebase.firestore().collection("forms").doc("WCohUlIMDVCKNsncV0Zx").collection("submissions").doc("tt3PgmgBlNwQylckcAvV").get().then(doc => {
		// 	firebase.firestore().collection("forms").doc("WCohUlIMDVCKNsncV0Zx").collection("submissions").add(doc.data())
		// 	// console.log(doc.data())
		// })

		return () => subs;
	}, []);

	const deleteForm = async (id) => {
		setDuplicating(true);
		try {
			const doc = firebase.firestore().collection("forms").doc(id);

			// const toDelete = (await doc.get()).data();
			// const stripeResp = await (
			// 	await fetch(process.env.REACT_APP_GCF_ROOT + "/deactivateProduct", {
			// 		method: "POST",
			// 		headers: {
			// 			Accept: "application/json",
			// 			"Content-Type": "application/json",
			// 		},
			// 		body: JSON.stringify({
			// 			productId: toDelete.productId,
			// 			priceId: toDelete.priceId,
			// 		}),
			// 	})
			// ).json();

			doc.delete();
			alert("Se ha eliminado el formulario correctamente");
			setDuplicating(false);
		} catch (err) {
			console.log(err);
			alert("Ha ocurrido un error al eliminar el formulario");
			setDuplicating(false);
		}
	};

	const duplicateCourse = async (d) => {
		setDuplicating(true);
		try {
			const variables = {
				...d,
				name: d.name + " - Copia",
			};
			delete variables.id;
			delete variables.ref;

			await firebase
				.firestore()
				.collection("forms")
				.add({
					...variables,
					active: false,
				});

			alert("Se ha duplicado el formulario correctamente");
			setDuplicating(false);
		} catch (err) {
			console.log(err);
			alert("Ha ocurrido un error al duplicar el formulario");
			setDuplicating(false);
		}
	};

	const getDataToExport = async () => {
		firebase
			.firestore()
			.collection("forms")
			.where("active", "==", true)
			.get()
			.then(async (query) => {
				const d = await Promise.all(
					query.docs.map(async (el) => {
						const subs = await el.ref
							.collection("submissions")
							.get()
							.then((res) =>
								res.docs.map((d) => {
									const ex = {
										curso: el.data().name,
										...d.data(),
										birthDate: moment(d.data()?.birthDate).format("DD/MM/YYYY"),
										files: d.data()?.files ? Object.values(d.data()?.files)?.map(el => el?.uri)?.join("\n") : ""
									};
									delete ex.lastUpdate;
									delete ex.createdAt;
									delete ex.updatedAt;
									delete ex.form;
									delete ex.formName;
									delete ex.files
									return ex;
								})
							);
						return subs;
					})
				);
				const final = d.reduce((acc, cur) => [...acc, ...cur], []);
				// console.log(final);
				exportToCSV(final, "Listado de Alumnos - " + moment().format("DD-MM-YYYY-hh:mm"));
			});
	};

	const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	const exportToCSV = (csvData, fileName) => {
		const ws = XLSX.utils.json_to_sheet(csvData);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
	};


	const addPricesDocument = async () => {

	}

	const columns = [
		{
			Header: "Nombre",
			accessor: "name",
			style: { display: "flex", alignItems: "center", padding: "10px 20px" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, paddingLeft: 20, fontSize: 12, textAlign: "left" },
		},
		{
			Header: "Estado",
			id: "active",
			width: 120,
			style: { display: "flex", justifyContent: "center", alignItems: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (datum) => <span style={{backgroundColor: !datum.active ? "#de5555" : "#62a62b", padding:"0px 10px", color:"white", fontSize:14, borderRadius: 7}}>{datum.active ? "Activo" : "Inactivo"}</span>,
		},
		{
			Header: "Plazas Disponibles",
			id: "spots",
			width: 150,
			style: { display: "flex", justifyContent: "center", alignItems: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (datum) => datum.spotsAvailable + " de " + datum.spotsTotal,
		},
		{
			Header: "Orden",
			accessor: "order",
			width: 100,
			style: { display: "flex", justifyContent: "center", alignItems: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
		},
		{
			Header: "Registrados",
			id: "users",
			width: 100,
			style: { display: "flex", justifyContent: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (row) => (
				<Link to={"/admin/formulario/" + row.id + "/registros"}>
					<Button>
						<IoPeople size={20} />
					</Button>
				</Link>
			),
		},
		{
			Header: "Visualizar",
			id: "preview",
			width: 100,
			style: { display: "flex", justifyContent: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (row) => (
				<Button onClick={() => window.open(window.location.origin + "/formulario/" + row.id)}>
					<IoEye size={20} />
				</Button>
			),
		},
		{
			Header: "Editar",
			id: "edit",
			width: 100,
			style: { display: "flex", justifyContent: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (row) => (
				<Link style={{ textDecoration: "none" }} to={"/admin/editarcurso/" + row.id}>
					<Button onClick={() => setModal(true)}>
						<IoPencil size={20} />
					</Button>
				</Link>
			),
		},
		// {
		// 	Header: "Tabla Precios",
		// 	id: "prices",
		// 	width: 100,
		// 	style: { display: "flex", justifyContent: "center" },
		// 	headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
		// 	accessor: (row) => (
		// 		<UploadButton courseId={row.id} />
		// 	),
		// },
		{
			Header: "Duplicar",
			id: "duplicate",
			width: 100,
			style: { display: "flex", justifyContent: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (row) => (
				<Button onClick={() => duplicateCourse(row)}>
					<IoDuplicate size={20} />
				</Button>
			),
		},
		{
			Header: "Eliminar",
			id: "delete",
			width: 100,
			style: { display: "flex", justifyContent: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (row) => (
				<Button
					onClick={() => {
						if (
							window.confirm(
								"Estas seguro que deseas eliminar este curso. Esta acción no puede deshacerse una vez realizada. Esto borrará el curso y todos los datos de formularios registrados."
							)
						) {
							deleteForm(row.id);
						} else {
							console.log("NOT DELETED");
						}
					}}
				>
					<IoTrash size={20} />
				</Button>
			),
		},
	];

	if (loading) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<Loader type="Puff" color="#141b59" height={100} width={100} />
			</div>
		);
	}
	if (error) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<div style={{ padding: 20, marginTop: 15, maxWidth: 600, marginBottom: 15, backgroundColor: "#DFDFDF", borderRadius: 10, textAlign: "center" }}>
					<p style={{ margin: 0, fontSize: 18 }}>Ha ocurrido un error al cargar esta página, inténtelo de nuevo o contacte con email@email.com</p>
					<img src="https://pa1.narvii.com/6545/83dacee449b3e918df1b7c5c1c03176ae0878159_hq.gif" width={"auto"} height={200} style={{ margin: "0px auto" }} />
				</div>
			</div>
		);
	}
	return (
		<div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{"Administración - Club Deportivo Nuevo Boadilla "}</title>
			</Helmet>

			{duplicating && (
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						display: "flex",
						backgroundColor: "rgba(0,0,0,.5)",
						zIndex: 300,
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Loader type="Puff" color="#141b59" height={100} width={100} />
				</div>
			)}

			<div style={{ backgroundColor: "#f3f3f3", padding: "0px 20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
				<h2>Cursos Activos</h2>
				<div style={{ display: "flex", flex: 1, justifyContent: "flex-end", height: 50 }}>
					<SearchBox
						style={{ minWidth: 300 }}
						placeholder="Buscar..."
						onChange={(ev) => setFiltered(data.filter((el) => el.name.toLocaleLowerCase().includes(ev.target.value.toLocaleLowerCase())))}
					/>
					<Link to="/admin/nuevocurso" style={{ textDecoration: "none" }}>
						<Button style={{ fontSize: 16, fontFamily: "Montserrat", height: 50, marginLeft: 10, width: 150 }}>Nuevo Curso</Button>
					</Link>
					<Button onClick={getDataToExport} style={{ fontSize: 16, fontFamily: "Montserrat", height: 50, marginLeft: 10, width: 150 }}>
						Lista de Alumnos
					</Button>
				</div>
			</div>
			<div style={{ padding: 15 }}>
				<ReactTable
					data={filtered}
					defaultPageSize={10}
					// loading={loading}
					loadingText="Cargando Datos..."
					previousText="Cursos Anteriores"
					nextText="Cursos Siguientes"
					noDataText="Sin Contenidos"
					pageText="Página"
					ofText="de"
					rowsText="filas"
					style={{ fontFamily: "Montserrat" }}
					columns={columns}
				/>
			</div>

			<Modal isOpen={modal} style={customStyles} contentLabel="Example Modal">
				<h2>Hello</h2>
				<button onClick={() => setModal(false)}>close</button>
				<div>I am a modal</div>
				<form>
					<input />
					<button>tab navigation</button>
					<button>stays</button>
					<button>inside</button>
					<button onClick={() => setModal(false)}>the modal</button>
				</form>
			</Modal>
		</div>
	);
};

export default AdminScreen;

const Button = styled.button`
	height: 35px;
	width: 35px;
	background-color: #141b59;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0px;
	color: white;
	border-radius: 7px;
	align-self: center;
`;
const SearchBox = styled.input`
	font-size: 16px;
	padding-left: 20px;
	padding-right: 20px;
`;



const UploadButton = ({ courseId }) => {
	const inputRef = useRef(null);
	const [loading, setLoading] = useState(false);


	const uploadFile = async (file) => {

		try {
			const storageRef = firebase.storage().ref();
			const fileRef = storageRef.child(`forms/${courseId}/${file.name}`)

			await fileRef.put(file)
			// .put(file)
			// .then((snapshot) => {
			// 	console.log("Uploaded a blob or file!", snapshot);
			// }).catch(err => {
			// 	throw err;
			// })

			//UPDATE THE FORM WITH THE FILE
			const docRef = firebase.firestore().collection("forms").doc(courseId);
			await docRef.update({
				prices: fileRef.fullPath,
			})

			console.log(fileRef.fullPath)


			alert("Archivo subido correctamente")

		} catch (err) {

			console.log(err)
			alert("Ha ocurrido un error al subir el archivo")
		} finally {
			setLoading(false);
		}
	}

	return (
		<>

			<Button onClick={() => inputRef.current.click()}>
				{loading ?
					<Loader type="Puff" color="white" height={10} width={10} />
					:
					<IoAttach size={20} />
				}
			</Button>


			<input
				ref={inputRef}
				type="file"
				accept="image/*,application/pdf"
				style={{ display: "none" }}
				onChange={(ev) => {
					setLoading(true);
					uploadFile(ev.target.files[0]);
				}}
			/>
		</>
	);
}