import React from "react";
import { Redirect } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const PrivateRoute = (props) => {
	if (props.user) {
		return (
			<Route path={props.path}>
				{props.children}
			</Route>
		);
	} else {
		return <Redirect to={{ pathname: `/login` }} />;
	}
};

export default PrivateRoute;
