import { useEffect, useState } from "react";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./components/PrivateRoute";

import AdminScreen from "./containers/AdminScreen";
import CourseForm from "./containers/CourseForm";
import FormPayment from "./containers/FormPayment";
import FormScreen from "./containers/FormScreen";
import FormSubmissions from "./containers/FormSubmissions";
import HomeScreen from "./containers/HomeScreen";
import LoginScreen from "./containers/LoginScreen";

import firebase from "firebase/app";
import "firebase/app-check";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import { Grommet } from "grommet";
import AvisoLegal from "./containers/AvisoLegal";



const firebaseConfig = {
	apiKey: "AIzaSyAZS8DWtbvXlQrjD3sr5e0xZQ6AonxwR2E",
	authDomain: "nuevo-boadilla.firebaseapp.com",
	projectId: "nuevo-boadilla",
	storageBucket: "nuevo-boadilla.appspot.com",
	messagingSenderId: "688134047887",
	appId: "1:688134047887:web:494ccff3db0140317848fa",
	measurementId: "G-SDH85X965P"
};



if (!firebase.apps.length) {

	firebase.initializeApp(firebaseConfig);

	if (process.env.NODE_ENV === 'development') {
		document.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
	}
	// firebase.functions().useEmulator("localhost", 5001)
	firebase.appCheck().activate("6LdQRSIgAAAAAF6OFgUsmwHxCrpHdCPT8uUq73op", true);
	// firebase.appCheck().activate("5DF9E3A0-EEE9-4A07-84F1-B862DA7A2316", true);
}


function App() {
	const [user, setUser] = useState(null);
	const [ready, setReady] = useState(null);

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				setUser(user);
				setReady(true);
			} else {
				setReady(true);
			}
		});
	}, []);

	const signOut = () => {
		firebase
			.auth()
			.signOut()
			.then(() => {
				// Sign-out successful.
				window.location.reload();
			})
			.catch((error) => {
				// An error happened.
			});
	};

	if (!ready) {
		return null;
	} else {
		return (
			<Grommet>
				<Router>
					<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
						<nav style={{ justifyContent: "space-between", alignItems: "center", padding: "5px 25px", display: "flex", flexDirection: "row", backgroundColor: "#141b59" }}>
							<Link to="/">
								<img src="/logo.png" height={60} width={60} />
							</Link>
							<div className="menu" style={{ display: "flex", flexDirection: "row" }}>
								<Link to="/">Cursos</Link>
								{user && <Link to="/admin">Administrador</Link>}
								{user && (
									<div onClick={signOut}>
										<Link to="/">Cerrar Sesión</Link>
									</div>
								)}
							</div>
						</nav>

						<Switch>
							{/* <PrivateRoute path="/admin/formulario/:id/registros" component={() => <FormSubmissions />} /> */}
							<PrivateRoute user={user} path="/admin/formulario/:id/registros">
								<FormSubmissions />
							</PrivateRoute>
							<PrivateRoute user={user} path="/admin/nuevocurso/">
								<CourseForm />
							</PrivateRoute>
							<PrivateRoute user={user} path="/admin/editarcurso/:id">
								<CourseForm />
							</PrivateRoute>
							<PrivateRoute user={user} path="/admin" exact>
								<AdminScreen />
							</PrivateRoute>

							<Route path="/formulario/:formId/verificacion/:id">
								<FormPayment />
							</Route>
							{/* <Route path="/verificacion">
									<FormPayment />
								</Route> */}
							<Route path="/formulario/:id">
								<FormScreen />
							</Route>
							<Route path="/avisolegal">
								<AvisoLegal />
							</Route>
							<Route path="/login">
								<LoginScreen />
							</Route>
							<Route path="/">
								<HomeScreen />
							</Route>
						</Switch>

						<footer>
							<a target="_blank" href="http://nuevoboadilla.ffmadrid.org">Club Deportivo Nuevo Boadilla</a>
							<Link target="_blank" to="/avisolegal">Aviso Legal y Política de Privacidad</Link>
						</footer>
					</div>
				</Router>
			</Grommet>
		);
	}
}

export default App;