import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from "react-router-dom";

const AvisoLegal = () => {

	return (
		<div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title style={{ fontFamily: "Montserrat" }}>{"CDNB | Aviso Legal"}</title>
			</Helmet>

			<div
				style={{
					height: 150,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					position: "relative",
					backgroundImage: "url(/field.jpg)",
					backgroundPosition: "center center",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					paddingLeft: 10,
					paddingRight: 10,
					boxSizing: "border-box"
				}}
			>
				{/* <img style={{ visibility: "hidden", position:"absolute" }} src="https://images.pexels.com/photos/114296/pexels-photo-114296.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" /> */}
				<h1 style={{ color: "white", lineHeight: "1.2em", textAlign: "center", fontSize: "2.5em" }}>Aviso Legal y Políticas de Privacidad</h1>
			</div>
			<div style={{maxWidth:1200, alignSelf:"center"}}>

				<p><strong>AVISO LEGAL</strong></p>
				<p>Club Deportivo Elemental Nuevo Boadilla: Club inscrito en el Registro de Entidades Deportivas de la Comunidad de Madrid con el número 5060 en la Sección de Clubes Deportivos Elementales a fecha 27 de junio de 2008.</p>
				<p>CIF: G-85528511.</p>
				<p>Dirección Postal: Complejo Deportivo Municipal Ángel Nieto, Carretera de Boadilla a Pozuelo km 1,400, 28660 Boadilla del Monte, MADRID</p>
				<p>Tlf. Contacto: 91 632 35 89.</p>
				<p>Email de Contacto: <a href="mailto:info@nuevoboadilla.es">info@nuevoboadilla.es</a></p>
				<p><strong>CONDICIONES DE USO</strong></p>
				<p>CLUB DEPORTIVO ELEMENTAL NUEVO BOADILLA (en adelante, C.D. Nuevo Boadilla), ofrece el acceso al sitio web www.nuevoboadilla.es en las condiciones de uso que se describen a continuación:</p>
				<p>Usuarios</p>
				<p>El acceso al sitio web www.nuevoboadilla.es atribuye a quien lo realiza la condición de USUARIO y expresa la aceptación plena y sin reservas por parte del USUARIO de todas y cada una de las presentes CONDICIONES DE USO, sin perjuicio de las condiciones particulares que sea preciso aplicar en el caso de que el USUARIO desee contratar alguno de los bienes y servicios que se le ofrecen. El sitio www.nuevoboadilla.es facilita al USUARIO diversa información puesta a disposición por C.D. Nuevo Boadilla. La prestación del servicio del website tiene carácter gratuito.</p>
				<p>Propiedad intelectual e industrial</p>
				<p>Los derechos de propiedad intelectual e industrial contenidos en la página web de www.nuevoboadilla.es, los nombres comerciales, marcas y demás signos distintivos son titularidad de C.D. Nuevo Boadilla, salvo que en las mismas se indique que pertenecen a otro titular. Cualquier reproducción, distribución, comercialización o transformación de los contenidos que no haya sido expresamente autorizada por sus titulares, constituye una infracción de los derechos de propiedad intelectual e industrial protegidos por la Ley.</p>
				<p>Contenido y uso adecuado</p>
				<p>C.D. Nuevo Boadilla garantiza que todos los contenidos y servicios que se ofrecen en www.nuevoboadilla.es respetan el principio de dignidad de la persona, el principio de no-discriminación por motivos de raza, sexo, religión, opinión, nacionalidad, discapacidad o cualquier otra circunstancia personal y social, así como el principio de protección de la juventud y de la infancia. Asimismo, el USUARIO se compromete a realizar un buen uso de los contenidos y servicios de www.nuevoboadilla.es, que nunca podrán ser utilizados para actividades ilícitas, o que puedan ser contrarias al orden público, la defensa nacional o la salud pública. Cualquier utilización por parte del USUARIO de los contenidos y servicios habrá de respetar los principios indicados en el párrafo anterior.</p>
				<p>Enlaces</p>
				<p>La página de www.nuevoboadilla.es puede proporcionar enlaces a otros contenidos que son propiedad de terceros. El único objeto de los enlaces es proporcionar al USUARIO la posibilidad de complementar la información a través de dichos enlaces. C.D. Nuevo Boadilla no se responsabiliza en ningún caso de los resultados que puedan derivarse al USUARIO por acceso a dichos enlaces.</p>
				<p>Exclusión de garantías y responsabilidades</p>
				<p>El acceso y la utilización de la página de www.nuevoboadilla.es se realizarán bajo la única y exclusiva responsabilidad del USUARIO. C.D. Nuevo Boadilla no garantiza ni se responsabiliza de los daños y perjuicios de toda naturaleza que se deban a las siguientes circunstancias:</p>
				<p> • La falta de continuidad del funcionamiento de www.nuevoboadilla.es, así como al funcionamiento incorrecto de la página web.
					<br /> • La falta de utilidad, adecuación o validez de los servicios y contenidos que se ofrecen en www.nuevoboadilla.es respecto a los resultados y expectativas del USUARIO.
					<br /> • La existencia de virus o programas en el ordenador del USUARIO o por la presencia de virus en los servicios prestados por terceros a través de www.nuevoboadilla.es.</p>
				<p>Jurisdicción y legislación aplicable</p>
				<p>Las presentes Condiciones de Uso se regirán por lo dispuesto en la legislación española. En el caso de que se produzca cualquier discrepancia o controversia con motivo de la interpretación o la aplicación de las presentes Condiciones de Uso o del contenido de la página Web, en general, C.D. Nuevo Boadilla y el USUARIO, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, se someten a la jurisdicción de los Juzgados y Tribunales del domicilio del USUARIO. En el supuesto de que el USUARIO tenga su domicilio fuera de España, C.D. Nuevo Boadilla y el USUARIO, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, se someten a la jurisdicción de los Juzgados y Tribunales del domicilio social de C.D. Nuevo Boadilla.</p>
				<p><strong>POLÍTICA DE PRIVACIDAD</strong></p>
				<p>Por medio de nuestra política de privacidad le ponemos al tanto de las debidas condiciones de uso en este sitio.</p>
				<p>La utilización de estos implica su aceptación plena y sin reservas a todas y cada una de las disposiciones incluidas en este Aviso Legal, por lo que si usted no está de acuerdo con cualquiera de las condiciones aquí establecidas, no deberá usar u/o acceder a este sitio.</p>
				<p>C.D. Nuevo Boadilla se reserva el derecho de modificar esta Declaración de Privacidad en cualquier momento. Su uso continuo de cualquier porción de este sitio tras la notificación o anuncio de tales modificaciones constituirá su aceptación de tales cambios.</p>
				<p>Cookies</p>
				<p>Este sitio hace uso de cookies, (pequeños ficheros de datos que se generan en su ordenador, este envía información sin proporcionar referencias que permitan deducir datos personales de este).</p>
				<p>Usted podrá configurar su navegador para que notifique y rechace la instalación de las cookies enviadas por este sitio, sin que ello perjudique la posibilidad de acceder a los contenidos. Sin embargo, C.D. Nuevo Boadilla no se responsabiliza de que la desactivación de los mismos impida el buen funcionamiento del sitio.</p>
				<p>Para desactivar las Cookies publicitarias y que los sistemas de Google Adsense no creen un perfil de su navegación, acceda a este enlace y haga click en “Inhabilitar”.</p>
				<p>Acciones de Terceros</p>
				<p>Asimismo, el USUARIO encontrará dentro de este sitio, páginas, promociones, micrositios, tiendas virtuales, encuestas, patrocinadores, publicistas, contratistas y/o socios y servicios comerciales, en conjunto con otros servicios compartidos, propios o cobrandeados con terceros (”Sitios Cobrandeados”) que le podrán solicitar Información, los cuales este sitio le podrá revelar información proporcionada por usted.</p>
				<p>La Información que se proporcione en estos Sitios Cobrandeados esta sujeta a las políticas de privacidad o leyendas de responsabilidad de Información que se desplieguen en dichos Sitios y no estará sujeta a esta política de privacidad.</p>
				<p>Política De Protección De Datos Personales</p>
				<p>Para recibir más información, deberá proporcionar ciertos datos de carácter personal, que solo serán utilizados para el propósito que fueron recopilados, tratados con todas las garantías legales y de seguridad que impone el Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos -RGPD-, así la Ley Orgánica 3/2018, de 5 de diciembre, de protección de datos personales y garantía de los derechos digitales y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico y resto de normativa española vigente. La facilitación de estos datos se considera consentimiento libre, expreso e inequívoco para su tratamiento, basándonos en el artículo 6.1.a) RGPD.</p>
				<p>El tipo de la posible Información que se le sea solicitada incluye, de manera enunciativa más no limitativa, su nombre y dirección de correo electrónico (e-mail).</p>
				<p>Sus datos han sido incorporados a nuestro Sistema de Protección de Datos y serán conservados mientras que la finalidad para su tratamiento esté vigente. Asimismo, mantendremos una copia bloqueada, mientras haya obligaciones legales por las que puedan requerírnoslos.</p>
				<p>C.D. Nuevo Boadilla no asume ninguna responsabilidad por los daños y perjuicios derivados de alteraciones que terceros pueden causar en los sistemas informáticos, documentos electrónicos o ficheros del usuario o motivados por causas de fuerza mayor.</p>
				<p>Le recordamos que puede retirar su consentimiento para el tratamiento de sus datos personales en cualquier momento, sin que ello afecte al tratamiento que se haya realizado con anterioridad. Asimismo, le recordamos que dispone del derecho de acceso a los datos disponibles, así como de los derechos de rectificación, de supresión, de oposición y de limitación de su tratamiento o portabilidad, conforme a lo establecido en el RGPD. Para ejercitar estos derechos, o para revocar su consentimiento al tratamiento de sus datos personales, deberá dirigirse por escrito a la dirección postal indicada más arriba o a la dirección de correo electrónico <a href="mailto:info@nuevoboadilla.es" target="_blank" rel="noopener noreferrer">info@nuevoboadilla.es</a> aportando copia de su DNI.</p>
				<p>En el caso de que lo considere procedente, puede presentar reclamación ante Agencia Española de Protección de Datos, como Autoridad de Control.</p>
				<p>Contacto</p>
				<p>Si tiene preguntas o cuestiones sobre esta Política, no dude en contactar en cualquier momento a través de nuestro correo electrónico <a href="mailto:info@nuevoboadilla.es" target="_blank" rel="noopener noreferrer">info@nuevoboadilla.es</a>.</p>

			</div>
		</div>
	);
};

export default AvisoLegal;
