import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import firebase from "firebase/app";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import moment from "moment";
import IBAN from "iban"

import AgreementModal from "../documents/AgreementModal";

const FormScreen = () => {
	const history = useHistory()
	const { id } = useParams();
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [sending, setSending] = useState(false);
	const [errorSending, setErrorSending] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);


	useEffect(() => {
		firebase
			.firestore()
			.collection("forms")
			.doc(id)
			.get()
			.then((res) => {
				setData({ ...res.data(), ref: res.ref, id: res.id });
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setError(true);
			});
		return () => null;
	}, []);


	const submitForm = async (form) => {
		// console.log(form)
		setSending(true);
		const subForm = firebase.app().functions("europe-west1").httpsCallable('submitForm');
		subForm({
			...form,
			form: data.id,
			formName: data.name
		})
			.then((res) => {
				// console.log(res)
				setCompleted(true);
				setSending(false);
				return res;
			})
			.catch((err) => {

				console.log(err.code, err.message);
				setSending(false);
				setErrorSending(err.message);
				return err;
			});

	};

	const SignupSchema = data.fields
		? Yup.object().shape(
			data?.fields.filter(el => el.type !== "title")
				?.map((f) => {
					let x = Yup;
					if (f.type === "string") {
						x = x.string("Este campo es requerido");
					} else if (f.type === "email") {
						x = x.string("Este campo es requerido").email("El formato del email introducido no es correcto");
					} else if (f.type === "date") {
						x = x.date("Fecha Incorrecta");
					} else if (f.type === "number") {
						x = x.number("El valor no es un número");
					} else if (f.type === "checkbox" && f.required) {
						x = x.bool("Este campo es requerido").oneOf([true], "Se debe aceptar este campo para continuar");
					} else if (f.type === "select" || f.required) {
						x = x.string("Este campo es requerido").min(1, "Selecciona una opción");
					} else {
						x = x.string("Este campo es requerido");
					}
					if (f.id === "iban") {
						x = x.test("isIBAN", "El formato del IBAN introducido no es correcto", (value) => IBAN.isValid(value))
					}
					if (f.min !== undefined) {
						x = x.min(f.min, `Este campo tiene que tener ${f.min} caracteres como mínimo`);
					}
					if (f.max !== undefined) {
						x = x.max(f.max, `Este campo tiene que tener ${f.max} caracteres como máximo`);
					}
					if (f.required) {
						x = x.required("Este campo es obligatorio");
					}
					return { [f.id]: x };
				})
				.reduce((a, c) => ({ ...a, ...c }), {}) || {}
		)
		: null;

	if (loading) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<Loader type="Puff" color="#141b59" height={100} width={100} />
			</div>
		);
	}
	if (error) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<div style={{ padding: 20, marginTop: 15, maxWidth: 600, marginBottom: 15, backgroundColor: "#DFDFDF", borderRadius: 10, textAlign: "center" }}>
					<p style={{ margin: 0, fontSize: 18 }}>Ha ocurrido un error al cargar este formulario, inténtelo de nuevo o contacte con info@nuevoboadilla.es</p>
					<img src="https://pa1.narvii.com/6545/83dacee449b3e918df1b7c5c1c03176ae0878159_hq.gif" width={"auto"} height={200} style={{ margin: "0px auto" }} />
				</div>
			</div>
		);
	}
	return (
		<div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{data.name || "Formulario Club Deportivo Nuevo Boadilla"}</title>
			</Helmet>

			<img src={data.image} width="100%" height={300} style={{ objectFit: "cover", border: 0 }} />
			<div style={{ padding: "15px 20px", width: "100%", flex: 1, display: "flex", flexDirection: "column", maxWidth: 960, alignSelf: "center", boxSizing: "border-box" }}>
				<h1 style={{ margin: 0, marginTop: 50, marginBottom: 30, fontSize: "2.5em" }}>{data.name}</h1>
				{errorSending ? (
					<div style={{ alignSelf: "center", padding: 20, marginTop: 15, maxWidth: 600, marginBottom: 15, backgroundColor: "#DFDFDF", borderRadius: 10, textAlign: "center" }}>
						<p style={{ margin: 0, fontSize: 18 }}>Ha ocurrido un error al enviar este formulario, inténtelo de nuevo o contacte con <a href={"mailto:info@nuevoboadilla.es"}>info@nuevoboadilla.es</a></p>
						<p style={{ margin: 0, fontSize: 18, marginTop: 20, marginBottom: 20 }}>{errorSending}</p>
						{/* <img src="https://pa1.narvii.com/6545/83dacee449b3e918df1b7c5c1c03176ae0878159_hq.gif" width={"auto"} height={200} style={{ margin: "0px auto" }} /> */}
						<button
							onClick={() => setErrorSending(false)}
							style={{
								height: 50,
								padding: "0px 40px",
								marginTop: 30,
								display: "flex",
								width: "100%",
								backgroundColor: "#141b59",
								border: 0,
								borderRadius: 5,
								alignItems: "center",
								justifyContent: "center",
								color: "white",
								fontSize: 16,
								fontFamily: "Montserrat, sans-serif",
								textDecoration: "none",
							}}
						>
							Volver a Intentar
						</button>
					</div>
				) : completed ? (
					<div style={{ padding: 20, marginTop: 15, marginBottom: 15, backgroundColor: "#f2f2f2", borderRadius: 10 }}>
						<h2 style={{ margin: 0, marginBottom: 15, fontSize: "2em" }}>Tu solicitud se ha enviado correctamente!</h2>
						<p style={{ margin: 0 }}>{data.postSubmissionMessage}</p>
						<div style={{ display: "flex" }}>
							<Link to="/" style={{ textDecoration: "none" }}>
								<button
									style={{
										height: 50,
										padding: "0px 40px",
										marginTop: 20,
										display: "flex",
										width: "100%",
										backgroundColor: "#141b59",
										border: 0,
										borderRadius: 5,
										alignItems: "center",
										justifyContent: "center",
										color: "white",
										fontSize: 16,
										fontFamily: "Montserrat, sans-serif",
										textDecoration: "none",
									}}
								>
									Volver al inicio
								</button>
							</Link>
						</div>
					</div>
				) : (
					<>
						{data.details && (
							<div style={{ padding: 20, marginTop: 15, marginBottom: 15, backgroundColor: "#f2f2f2", borderRadius: 10 }}>
								<p style={{ margin: 0 }}>{data.details}</p>
							</div>
						)}
						{data.fields && (
							<Formik
								initialValues={data?.fields?.filter(el => el.type !== "title")?.map((el) => ({ [el.id]: el.defaultValue || "" })).reduce((a, c) => ({ ...a, ...c }), {}) || {}}
								validationSchema={SignupSchema}								// validate={(ev) => console.log(ev)}

							>
								{({ values, errors, touched, handleChange, handleBlur, setFieldValue, setTouched, setErrors, validateForm }) => (
									<form style={{ display: "flex", flexWrap: "wrap" }}>
										{data?.fields?.map((field, i) => {
											if (field.type === "title") {
												return (
													<div key={i} style={{ width: "100%", marginTop: 20, display: "flex", flexDirection: "column" }}>
														<h3 style={{ fontSize: 30 }}>{field.name}</h3>
													</div>
												)
											} else if (field.type === "select") {
												return (
													<div key={i} className={field.layout} style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
														<label htmlFor={field.id} style={{ marginBottom: 10, color: errors[field.id] && touched[field.id] && errors[field.id] ? "red" : "black" }}>
															{field.name}
														</label>
														<select
															id={field.id}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values[field.id]}
															style={{
																height: 50,
																fontSize: 16,
																paddingLeft: 10,
																paddingRight: 10,
																fontFamily: "Montserrat",
																borderRadius: 5,
																border: `${errors[field.id] && touched[field.id] && errors[field.id] ? "red" : "gainsboro"} solid 2px`,
															}}
															placeholder={field.name}
														>
															<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="">
																Selecciona una opción...
															</option>
															{field.options.map((option, i) => {
																return (
																	<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value={option} key={i}>
																		{option}
																	</option>
																);
															})}
														</select>
														{errors[field.id] && touched[field.id] && (
															<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
																<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
																	<path
																		fill="red"
																		d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
																	/>
																</svg>
																<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors[field.id]}</span>
															</div>
														)}
													</div>
												);
											} else if (field.type === "textarea") {
												return (
													<div key={i} className={field.layout} style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
														<label htmlFor={field.id} style={{ marginBottom: 10, color: errors[field.id] && touched[field.id] && errors[field.id] ? "red" : "black" }}>
															{field.name}
														</label>
														<textarea
															id={field.id}
															type={field.type}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values[field.id]}
															rows={field.rows || 6}
															style={{
																borderRadius: 5,
																border: `${errors[field.id] && touched[field.id] && errors[field.id] ? "red" : "gainsboro"} solid 2px`,
																fontSize: 16,
																padding: 10,
																fontFamily: "Montserrat",
															}}
															placeholder={field.name}
														/>
														{errors[field.id] && touched[field.id] && (
															<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
																<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
																	<path
																		fill="red"
																		d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
																	/>
																</svg>
																<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors[field.id]}</span>
															</div>
														)}
													</div>
												);
											} else if (field.type === "checkbox") {
												return (
													<div key={i} className={field.layout} style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
														<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
															<input
																id={field.id}
																type={field.type}
																onClick={(ev) => {
																	(field.id === "datapolicy" || field.id === "consentimientoDeporte" || field.id === "dataProtection") ? setModalOpen(field.id) : setFieldValue(field.id, !values[field.id]);
																}}
																// onChange={(ev) => setFieldValue(field.id, ev.target.checked)}
																onBlur={handleBlur}
																value={values[field.id]}
																style={{
																	borderRadius: 5,
																	border: `${errors[field.id] && touched[field.id] && errors[field.id] ? "red" : "gainsboro"} solid 2px`,
																	fontFamily: "Montserrat",
																	width: 25,
																	height: 25,
																}}
																checked={values[field.id]}
																placeholder={field.name}
																label={field.name}
															/>
															<label htmlFor={field.id} style={{ marginLeft: 10, color: errors[field.id] && touched[field.id] && errors[field.id] ? "red" : "black" }}>
																{field.name}
															</label>
														</div>
														{errors[field.id] && touched[field.id] && (
															<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
																<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
																	<path
																		fill="red"
																		d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
																	/>
																</svg>
																<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors[field.id]}</span>
															</div>
														)}
													</div>
												);
											} else {
												return (
													<div key={i} className={field.layout} style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
														<label htmlFor={field.id} style={{ marginBottom: 10, color: errors[field.id] && touched[field.id] && errors[field.id] ? "red" : "black" }}>
															{field.name}
														</label>
														<input
															id={field.id}
															type={field.type}
															onChange={(ev) => (field.type === "date" ? setFieldValue(field.id, moment(ev.target.value).toDate()) : setFieldValue(field.id, ev.target.value))}
															onBlur={handleBlur}
															value={field.type === "date" ? moment(values[field.id]).format("YYYY-MM-DD") : values[field.id]}
															style={{
																borderRadius: 5,
																border: `${errors[field.id] && touched[field.id] && errors[field.id] ? "red" : "gainsboro"} solid 2px`,
																height: 45,
																fontSize: 16,
																paddingLeft: 10,
																paddingRight: 10,
																fontFamily: "Montserrat",
															}}
															placeholder={field.name}
														/>
														{errors[field.id] && touched[field.id] && (
															<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
																<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
																	<path
																		fill="red"
																		d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
																	/>
																</svg>
																<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors[field.id]}</span>
															</div>
														)}
													</div>
												);
											}
										})}
										<div style={{ marginTop: 30, display: "flex", justifyContent: "flex-end" }}>
											<button
												style={{
													height: 50,
													display: "flex",
													padding: "0px 50px",
													backgroundColor: "#141b59",
													border: 0,
													borderRadius: 5,
													alignItems: "center",
													justifyContent: "center",
													color: "white",
													fontSize: 16,
													opacity: sending ? .7 : 1,
													fontFamily: "Montserrat, sans-serif",
												}}
												type="submit"
												onClick={(ev) => {
													ev.preventDefault()
													validateForm().then(res => {
														setTouched(res, true)
														Object.keys(res).length === 0 && submitForm(values)
														// setErrors({ "name": "ERRORRRR", "email": "ASDASDAS", "birthDate": "WTFFF" })
													})
												}}
												disabled={sending}
											>
												{sending ? "Enviando" : "Enviar"}
											</button>
										</div>
										<AgreementModal
											open={modalOpen}
											content={modalOpen}
											acceptCallback={() => {
												setFieldValue(modalOpen, true);
												setModalOpen(false);
											}}
											cancelCallback={() => {
												setFieldValue(modalOpen, false);
												setModalOpen(false);
											}}
										/>
									</form>
								)}
							</Formik>
						)}
					</>
				)}
			</div>
		</div >
	);
};

export default FormScreen;