import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import { Text, Box, TextInput, Button, Image, Keyboard } from "grommet";

import firebase from "firebase/app";

const LoginScreen = ({ history }) => {
	// Declare a new state variable, which we'll call "count"
	const [username, setUser] = useState("");
	const [password, setPass] = useState("");
	const [email, setEmail] = useState("");
	const [wrongPass, setWrongPass] = useState(false);
	const [wrongUser, setWrongUser] = useState(false);
	const [checking, setChecking] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [reset, setReset] = useState(false);

	const signIn = async () => {
		setChecking(true);
		firebase
			.auth()
			.signInWithEmailAndPassword(username, password)
			.then((userCredential) => {
				// Signed in
				var user = userCredential.user;
				// ...
				setChecking(false);
                setTimeout(() => {
                    history.push("/admin");
                })
			})
			.catch((error) => {
				var errorCode = error.code;
				var errorMessage = error.message;
				console.log(error);
				setChecking(false);
			});
	};

	const resetFunc = () => {
		firebase
			.auth()
			.sendPasswordResetEmail(email)
			.then((a) => {
				// Email sent.
				setSuccess("El correo electrónico se ha enviado correctamente. Revisa tu bandeja de entrada, en breves momentos podrás recuperar tu contraseña.");
				setEmail("");
				setReset(!reset);
			})
			.catch((error) => {
				// An error happened.
				setError(error);
			});
	};

	return (
		<Box fill="vertical" flex={"grow"} justify="center" style={{ position: "relative", minHeight: "76vh" }}>

			<Keyboard onEnter={() => (!reset ? signIn() : resetFunc())}>
				<Box gap="small" pad="medium" style={{alignSelf:"center", width:"100%", maxWidth:450}}>
					{!reset ? (
						<>
							<TextInput
								id="user"
								type="text"
								value={username}
								placeholder="Email..."
								onChange={(event) => {
									setUser(event.target.value);
									setWrongPass(false);
								}}
							/>
							<TextInput
								id="pass"
								type="password"
								value={password}
								placeholder="Contraseña..."
								style={{ marginTop: 15 }}
								onChange={(event) => {
									setPass(event.target.value);
									setWrongPass(false);
								}}
							/>
						</>
					) : (
						<TextInput id="email" type="email" value={email} placeholder="Email" onChange={(event) => setEmail(event.target.value)} />
					)}

					<Button
						disabled={checking}
						onClick={() => (!reset ? signIn() : resetFunc())}
						primary
						color="#141b59"
						style={{ borderRadius: 7 }}
						label={!reset ? (checking ? "Accediendo..." : "Acceder") : "Recuperar contraseña"}
					/>

					{error !== false && (
						<Box onClick={() => setError(false)}>
							<Text>{error}</Text>
						</Box>
					)}

					{success !== false && (
						<Box onClick={() => setSuccess(false)}>
							<Text color="#4BB543">{success}</Text>
						</Box>
					)}

					<Box align="center" onClick={() => setReset(!reset)}>
						<Text>{!reset ? "Recuperar Contraseña" : "Login"}</Text>
					</Box>
				</Box>
			</Keyboard>
		</Box>
	);
};

export default withRouter(LoginScreen);
