import firebase from "firebase/app";
import { Field, FieldArray, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Helmet } from "react-helmet";
import { IoAdd, IoArrowDown, IoArrowUp, IoDuplicate, IoRemove } from "react-icons/io5";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Modal from "react-modal";
import { useHistory, useParams } from "react-router-dom";
import "react-table/react-table.css";
import slugify from "slugify";
import styled from "styled-components";
import * as Yup from "yup";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},

	overlay: {
		backgroundColor: "rgba(0,0,0,.7)",
	},
};

const CourseForm = () => {
	const history = useHistory();

	const [data, setData] = useState({
		name: "",
		details: "",
		image: "",
		active: "false",
		fields: [],
		paymentDetails: "",
		paymentMode: "BANKTRANSFER",
		postSubmissionMessage: "",
		price: 0,
		priceMonthly: 0,
		chedule: "",
		spotsTotal: 0,
		order: 0,
	});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [modal, setModal] = useState(false);

	const [oldName, setOldName] = useState()
	const [oldAmount, setOldAmount] = useState()

	const [uploading, setUploading] = useState(false);
	const [errorUploading, setErrorUploading] = useState(false);
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			firebase
				.firestore()
				.collection("forms")
				.doc(id)
				.get()
				.then((doc) => {
					setData({ ...doc.data(), active: doc.data().active ? "true" : "false", id: doc.id, ref: doc.ref });
					setOldName(doc.name)
					setOldAmount(doc.price)
					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					setError(true);
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	}, [id]);

	const createCourse = async (values) => {
		try {

			await firebase
				.firestore()
				.collection("forms")
				.add({
					...values,
					active: values.active === "true",
					createdAt: firebase.firestore.FieldValue.serverTimestamp(),
					updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
				})

			history.push("/admin");
		}
		catch (err) { console.log(err) }
	};

	const updateCourse = async (values) => {
		const v = { ...values };

		delete v.ref;
		delete v.id;

		firebase
			.firestore()
			.collection("forms")
			.doc(id)
			.update({
				...v,
				active: v.active === "true",
				updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
			})
			.then(res => {
				history.push("/admin");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	

	const SignupSchema = Yup.object().shape({
		name: Yup.string().min(3, "El nombre del curso es demasiado corto"),
	});

	if (loading) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<Loader type="Puff" color="#141b59" height={100} width={100} />
			</div>
		);
	}
	if (error) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<div style={{ padding: 20, marginTop: 15, maxWidth: 600, marginBottom: 15, backgroundColor: "#DFDFDF", borderRadius: 10, textAlign: "center" }}>
					<p style={{ margin: 0, fontSize: 18 }}>Ha ocurrido un error al cargar esta página, inténtelo de nuevo o contacte con email@email.com</p>
					<img src="https://pa1.narvii.com/6545/83dacee449b3e918df1b7c5c1c03176ae0878159_hq.gif" width={"auto"} height={200} style={{ margin: "0px auto" }} />
				</div>
			</div>
		);
	}
	return (
		<div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{`${id ? "Editar Curso " + data.name : "Nuevo Curso"} - Club Deportivo Nuevo Boadilla `}</title>
			</Helmet>

			<Formik
				initialValues={data}
				validationSchema={SignupSchema}
				onSubmit={(values, { setSubmitting }) => {
					// console.log(values);
					if (id) {
						updateCourse(values);
					} else {
						createCourse(values);
					}
				}}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => {
					return (
						<form onSubmit={handleSubmit}>
							<div style={{ backgroundColor: "#f3f3f3", padding: "10px 20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
								<h1 style={{ fontSize: "2.5em" }}>{id ? `Editar Curso ${data.name}` : "Nuevo Curso"}</h1>
								<button
									style={{
										height: 50,
										display: "flex",
										padding: "0px 50px",
										backgroundColor: "#141b59",
										border: 0,
										borderRadius: 5,
										alignItems: "center",
										justifyContent: "center",
										color: "white",
										fontSize: 16,
										fontFamily: "Montserrat, sans-serif",
									}}
									type="submit"
									disabled={isSubmitting}
								>
									{isSubmitting ? "Guardando" : id ? "Guardar Curso" : "Crear Curso"}
								</button>
							</div>

							<div className="fieldBlock" style={{ padding: 15, maxWidth: 1000, margin: "0px auto" }}>
								<div style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
									<label htmlFor={"image"} style={{ marginBottom: 10, color: errors.image && touched.image && errors.image ? "red" : "black" }}>
										Imagen del Curso
									</label>

									<Dropzone
										onDrop={(acceptedFiles) => {
											setUploading(true);
											const file = acceptedFiles[0];
											const name = slugify(acceptedFiles[0].name.replace(/\.[^/.]+$/, ""));
											const format = slugify(acceptedFiles[0].name.split(".")[1]);

											var storageRef = firebase.storage().ref().child(`images/${name}.${format}`);

											storageRef
												.put(file)
												.then(async (snapshot) => {
													console.log(snapshot.ref.fullPath)
													const d = await firebase
														.storage()
														.ref(snapshot.ref.fullPath)
														.getDownloadURL()
														.then((res) => res)
														.catch((err) => "");

													await setFieldValue("image", d);
													await setUploading(false);
												})
												.catch((err) => {
													console.log(err)
													setErrorUploading(true);
													setUploading(false);
												});
										}}
									>
										{({ getRootProps, getInputProps, isFocused, isDragActive }) => (
											<section>
												<div
													{...getRootProps()}
													style={{
														backgroundColor: "white",
														border: isDragActive ? "3px solid green" : "3px solid gainsboro",
														padding: 15,
														height: 200,
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														position: "relative",
														overflow: "hidden",
														borderRadius: 10,
													}}
												>
													<input {...getInputProps()} />
													{!uploading && (
														<div style={{ zIndex: 10, background: "white", padding: "0px 20px", cursor: "pointer", borderRadius: 5 }}>
															{isDragActive ? <p>Suelta aqui tu archivo ...</p> : <p>Arrastra la imagen o haz click aquí...</p>}
														</div>
													)}
													{uploading && (
														<div
															style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 20, display: "flex", alignItems: "center", justifyContent: "center" }}
														>
															<Loader type="Puff" color="#141b59" height={80} width={80} />
														</div>
													)}
													{values.image && (
														<div
															style={{
																position: "absolute",
																backgroundImage: `url(${values.image})`,
																backgroundPosition: "center center",
																backgroundSize: "cover",
																backgroundColor: "#f3f3f3",
																width: "100%",
																height: "100%",
																top: 0,
																left: 0,
																right: 0,
																bottom: 0,
															}}
														/>
													)}
												</div>
											</section>
										)}
									</Dropzone>
								</div>

								<div style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
									<label htmlFor={"name"} style={{ marginBottom: 10, color: errors.name && touched.name && errors.name ? "red" : "black" }}>
										Nombre del Curso
									</label>
									<Input id={"name"} type={"input"} onChange={handleChange} onBlur={handleBlur} value={values.name} placeholder={"Nombre del cruso..."} />
									{errors.name && touched.name && (
										<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
											<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
												<path
													fill="red"
													d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
												/>
											</svg>
											<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.name}</span>
										</div>
									)}
								</div>

								<div style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
									<label htmlFor={"details"} style={{ marginBottom: 10, color: errors.details && touched.details && errors.details ? "red" : "black" }}>
										Detalles del Curso <small>(Este texto aparecerá en la ficha del curso)</small>
									</label>
									<TextArea rows={6} id={"details"} type={"input"} onChange={handleChange} onBlur={handleBlur} value={values.details} placeholder={"Detalles del cruso..."} />
									{errors.details && touched.details && (
										<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
											<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
												<path
													fill="red"
													d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
												/>
											</svg>
											<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.details}</span>
										</div>
									)}
								</div>

								<div style={{ height: 5, backgroundColor: "gainsboro", marginTop: 50, marginBottom: 50, width: "100%", borderRadius: 3 }} />

								<h2 style={{ marginTop: 0, marginBottom: 0 }}>Estado</h2>
								<div style={{ marginTop: 20, display: "flex", flexDirection: "row" }}>
									<div style={{ marginTop: 20, flex: 1, display: "flex", flexDirection: "column" }}>
										<label htmlFor={"active"} style={{ marginBottom: 10, color: errors.active && touched.active && errors.active ? "red" : "black" }}>
											Estado del Curso
										</label>

										<Select id={"active"} onChange={handleChange} onBlur={handleBlur} value={values.active} placeholder={"Estado del Curso"}>
											<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value={true}>
												Activo
											</option>
											<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value={false}>
												Inactivo
											</option>
										</Select>
										{errors.active && touched.active && (
											<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
												<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
													<path
														fill="red"
														d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
													/>
												</svg>
												<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.active}</span>
											</div>
										)}
									</div>
									<div style={{ marginLeft: 10, flex: 1, marginTop: 20, width: 100, display: "flex", flexDirection: "column" }}>
										<label htmlFor={"order"} style={{ marginBottom: 10, color: errors.order && touched.order && errors.order ? "red" : "black" }}>
											Orden
										</label>
										<Input id={"order"} type={"number"} onChange={handleChange} onBlur={handleBlur} value={values.order} placeholder={"Orden..."} />
										{errors.order && touched.order && (
											<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
												<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
													<path
														fill="red"
														d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
													/>
												</svg>
												<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.order}</span>
											</div>
										)}
									</div>
									<div style={{ marginLeft: 10, flex: 1, marginTop: 20, width: 100, display: "flex", flexDirection: "column" }}>
										<label htmlFor={"spotsTotal"} style={{ marginBottom: 10, color: errors.spotsTotal && touched.spotsTotal && errors.spotsTotal ? "red" : "black" }}>
											Plz. Totales
										</label>
										<Input id={"spotsTotal"} type="number" onChange={handleChange} onBlur={handleBlur} value={values.spotsTotal} placeholder={"Plazas Totales..."} />
										{errors.spotsTotal && touched.spotsTotal && (
											<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
												<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
													<path
														fill="red"
														d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
													/>
												</svg>
												<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.spotsTotal}</span>
											</div>
										)}
									</div>
									<div style={{ marginLeft: 10, flex: 1, marginTop: 20, width: 100, display: "flex", flexDirection: "column" }}>
										<label
											htmlFor={"spotsAvailable"}
											style={{ marginBottom: 10, color: errors.spotsAvailable && touched.spotsAvailable && errors.spotsAvailable ? "red" : "black" }}
										>
											Plz. Disponibles
										</label>
										<Input id={"spotsAvailable"} type="number" onChange={handleChange} onBlur={handleBlur} value={values.spotsAvailable} placeholder={"Plazas Disponibles..."} />
										{errors.spotsAvailable && touched.spotsAvailable && (
											<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
												<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
													<path
														fill="red"
														d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
													/>
												</svg>
												<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.spotsAvailable}</span>
											</div>
										)}
									</div>
									<div style={{ marginLeft: 10, flex: 2, marginTop: 20, display: "flex", flexDirection: "column" }}>
										<label htmlFor={"schedule"} style={{ marginBottom: 10, color: errors.schedule && touched.schedule && errors.schedule ? "red" : "black" }}>
											Horarios
										</label>
										<Input id={"schedule"} type={"input"} onChange={handleChange} onBlur={handleBlur} value={values.schedule} placeholder={"Horarios..."} />
										{errors.schedule && touched.schedule && (
											<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
												<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
													<path
														fill="red"
														d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
													/>
												</svg>
												<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.schedule}</span>
											</div>
										)}
									</div>
								</div>

								<div style={{ height: 5, backgroundColor: "gainsboro", marginTop: 50, marginBottom: 50, width: "100%", borderRadius: 3 }} />

								<h2 style={{ marginTop: 0, marginBottom: 0 }}>Pago de Matricula</h2>
								<div style={{ marginTop: 20, display: "flex", flexDirection: "row" }}>
									<div style={{ marginTop: 20, flex: 1, display: "flex", flexDirection: "column" }}>
										<label htmlFor={"paymentMode"} style={{ marginBottom: 10, color: errors.paymentMode && touched.paymentMode && errors.paymentMode ? "red" : "black" }}>
											Forma de Pago
										</label>

										<Select id={"paymentMode"} onChange={handleChange} onBlur={handleBlur} value={values.paymentMode} style={{}} placeholder={"Forma de Pago"}>
											<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value={"BANKTRANSFER"}>
												Transferencia Bancaria
											</option>
											<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value={"w"}>
												Tarjeta
											</option>
										</Select>
										{errors.paymentMode && touched.paymentMode && (
											<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
												<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
													<path
														fill="red"
														d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
													/>
												</svg>
												<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.paymentMode}</span>
											</div>
										)}
									</div>
									<div style={{ marginTop: 20, flex: 1, marginLeft: 10, display: "flex", flexDirection: "column" }}>
										<label htmlFor={"price"} style={{ marginBottom: 10, color: errors.price && touched.price && errors.price ? "red" : "black" }}>
											Precio de la Matrícula
										</label>
										<Input id={"price"} type="number" onChange={handleChange} onBlur={handleBlur} value={values.price} placeholder={"Precio de la Matrícula..."} />
										{errors.price && touched.price && (
											<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
												<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
													<path
														fill="red"
														d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
													/>
												</svg>
												<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.price}</span>
											</div>
										)}
									</div>
									<div style={{ marginTop: 20, flex: 1, marginLeft: 10, display: "flex", flexDirection: "column" }}>
										<label htmlFor={"priceMonthly"} style={{ marginBottom: 10, color: errors.priceMonthly && touched.priceMonthly && errors.priceMonthly ? "red" : "black" }}>
											Precio de la Mensualidad
										</label>
										<Input id={"priceMonthly"} type="number" onChange={handleChange} onBlur={handleBlur} value={values.priceMonthly} placeholder={"Precio de la Mensualidad..."} />
										{errors.priceMonthly && touched.priceMonthly && (
											<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
												<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
													<path
														fill="red"
														d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
													/>
												</svg>
												<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.priceMonthly}</span>
											</div>
										)}
									</div>
								</div>
								<div style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
									<label
										htmlFor={"postSubmissionMessage"}
										style={{ marginBottom: 10, color: errors.postSubmissionMessage && touched.postSubmissionMessage && errors.postSubmissionMessage ? "red" : "black" }}
									>
										Mensaje al Completar el formulario
									</label>
									<TextArea
										rows={6}
										id={"postSubmissionMessage"}
										type={"input"}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.postSubmissionMessage}
										placeholder={"Escribe aquí el mensaje que aparecerá tras completar el pago..."}
									/>
									{errors.postSubmissionMessage && touched.postSubmissionMessage && (
										<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
											<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
												<path
													fill="red"
													d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
												/>
											</svg>
											<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.postSubmissionMessage}</span>
										</div>
									)}
								</div>
								<div style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
									<label htmlFor={"paymentDetails"} style={{ marginBottom: 10, color: errors.paymentDetails && touched.paymentDetails && errors.paymentDetails ? "red" : "black" }}>
										Información de pago <small>(Este texto aparecerá en la página de confirmación de pago)</small>
									</label>
									<TextArea
										rows={6}
										id={"paymentDetails"}
										type={"input"}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.paymentDetails}
										placeholder={"Escribe aquí los detalles del pago como el numeo de cuenta o información adicional..."}
									/>
									{errors.paymentDetails && touched.paymentDetails && (
										<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
											<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
												<path
													fill="red"
													d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
												/>
											</svg>
											<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.paymentDetails}</span>
										</div>
									)}
								</div>
								<div style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
									<label htmlFor={"paymentDetails"} style={{ marginBottom: 10, color: errors.paymentDetails && touched.paymentDetails && errors.paymentDetails ? "red" : "black" }}>
										Mensaje tras cargar Documentación
									</label>
									<TextArea
										rows={6}
										id={"postPaymentMessage"}
										type={"input"}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.postPaymentMessage}
										placeholder={"Escribe aquí los detalles del pago como el numeo de cuenta o información adicional..."}
									/>
									{errors.postPaymentMessage && touched.postPaymentMessage && (
										<div style={{ flexDirection: "row", display: "flex", marginTop: 5, alignItems: "center" }}>
											<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 416.979 416.979">
												<path
													fill="red"
													d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"
												/>
											</svg>
											<span style={{ marginLeft: 5, color: "red", fontSize: 12 }}>{errors.postPaymentMessage}</span>
										</div>
									)}
								</div>



								<div style={{ height: 5, backgroundColor: "gainsboro", marginTop: 50, marginBottom: 50, width: "100%", borderRadius: 3 }} />

								<FieldArray name="fields">
									{({ insert, remove, push, swap }) => {
										return (
											<div>
												<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 15 }}>
													<h2 style={{ marginTop: 0, marginBottom: 0 }}>Campos del formulario</h2>
													<div style={{ display: "flex", flexDirection: "row" }}>
														<Button style={{ color: "white", width: 150 }} type="button" onClick={() => push({ name: "", id: "", placeholder: "", type: "input" })}>
															<IoAdd size={24} />
															<span style={{ marginLeft: 5, color: "white" }}>Añadir Campo</span>
														</Button>
													</div>
												</div>
												{values.fields.length === 0 ? (
													<div
														style={{ backgroundColor: "#f3f3f3", height: 200, width: "100%", borderRadius: 15, alignItems: "center", justifyContent: "center", display: "flex" }}
													>
														<span style={{ fontSize: 24, opacity: 0.5 }}>No hay campos en el formulario</span>
													</div>
												) : (
													values.fields.map((field, index) => {
														const parentIndex = index;
														return (
															<div className="row" key={index} style={{ backgroundColor: "#f2f2f2", padding: 10, borderRadius: 5, marginBottom: 15, position: "relative" }}>
																<FieldBox className="col">
																	<div style={{ minWidth: 200 }}>
																		<label htmlFor={`fields[${index}].name`}>Nombre</label>
																	</div>
																	<Field
																		style={{ height: 30, borderRadius: 5, minWidth: 200, border: "2px gainsboro solid", paddingLeft: 10, paddingRight: 10 }}
																		name={`fields[${index}].name`}
																		placeholder="Nombre del Campo..."
																		type="text"
																	/>
																</FieldBox>
																<FieldBox className="col">
																	<div style={{ minWidth: 200 }}>
																		<label htmlFor={`fields[${index}]type`}>Tipo de Campo</label>
																	</div>
																	<Field
																		component="select"
																		name={`fields[${index}]type`}
																		placeholder="Tipo de Campo..."
																		style={{
																			height: 35,
																			borderRadius: 5,
																			minWidth: 225,
																			border: "2px gainsboro solid",
																			paddingLeft: 10,
																			paddingRight: 10,
																			backgroundColor: "white",
																		}}
																	>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="input">
																			Entrada de Texto
																		</option>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="number">
																			Campo de Numero
																		</option>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="date">
																			Campo de Fecha
																		</option>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="select">
																			Selector
																		</option>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="textarea">
																			Campo de Texto
																		</option>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="checkbox">
																			Campo de Check
																		</option>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="fileUpload">
																			Subida de Archivo
																		</option>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="title">
																			Título Separador
																		</option>
																	</Field>
																</FieldBox>
																{values?.fields[index]?.type !== "title" && <FieldBox className="col">
																	<div style={{ minWidth: 200 }}>
																		<label htmlFor={`fields[${index}].id`}>ID</label>
																	</div>
																	<Field
																		style={{ height: 30, borderRadius: 5, minWidth: 200, border: "2px gainsboro solid", paddingLeft: 10, paddingRight: 10 }}
																		name={`fields[${index}].id`}
																		placeholder="ID del campo..."
																		type="text"
																	/>
																</FieldBox>}
																{values?.fields[index]?.type !== "title" && <FieldBox className="col">
																	<div style={{ minWidth: 200 }}>
																		<label htmlFor={`fields[${index}].required`}>Campo Obligatorio</label>
																	</div>
																	<Field
																		style={{ height: 20, width: 20, backgroundColor: "#141b59", borderRadius: 5, border: "2px gainsboro solid", paddingLeft: 10, paddingRight: 10 }}
																		name={`fields[${index}].required`}
																		type="checkbox"
																	/>
																</FieldBox>}
																{values?.fields[index]?.type !== "title" && <FieldBox className="col">
																	<div style={{ minWidth: 200 }}>
																		<label htmlFor={`fields[${index}].placeholder`}>Placeholder</label>
																	</div>
																	<Field
																		style={{ height: 30, borderRadius: 5, minWidth: 200, border: "2px gainsboro solid", paddingLeft: 10, paddingRight: 10 }}
																		name={`fields[${index}].placeholder`}
																		placeholder="Valor sin datos..."
																		type="text"
																	/>
																</FieldBox>}
																{values?.fields[index]?.type !== "title" && <FieldBox className="col">
																	<div style={{ minWidth: 200 }}>
																		<label htmlFor={`fields[${index}]layout`}>Layout</label>
																	</div>
																	<Field
																		component="select"
																		name={`fields[${index}]layout`}
																		placeholder="Tipo de Campo..."
																		style={{
																			height: 35,
																			borderRadius: 5,
																			minWidth: 225,
																			border: "2px gainsboro solid",
																			paddingLeft: 10,
																			paddingRight: 10,
																			backgroundColor: "white",
																		}}
																	>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="25">
																			25%
																		</option>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="50">
																			50%
																		</option>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="75">
																			75%
																		</option>
																		<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value="100">
																			100%
																		</option>

																	</Field>
																</FieldBox>}


																{values?.fields[index]?.type === "select" && (
																	<div style={{ flexDirection: "column", marginLeft: 10, background: "white", borderRadius: 10, marginTop: 10, padding: 10 }}>
																		Opciones del Campo
																		<FieldArray name={`fields[${index}].options`}>
																			{({ insert, remove, push }) => (
																				<div>
																					{values.fields[index]?.options?.length > 0 &&
																						values?.fields[index].options.map((field, i) => (
																							<div className="row" key={i} style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
																								<Field
																									style={{ height: 30, borderRadius: 5, border: "2px gainsboro solid", paddingLeft: 10, paddingRight: 10 }}
																									name={`fields[${parentIndex}].options[${i}]`}
																									placeholder="Nombre del Campo..."
																									type="text"
																								/>

																								<Button style={{ marginLeft: 10 }} type="button" className="secondary" onClick={() => remove(i)}>
																									<IoRemove />
																								</Button>
																							</div>
																						))}
																					<Button type="button" onClick={() => push("")} style={{ color: "white", width: 150, marginTop: 15 }}>
																						<IoAdd size={24} />
																						<span style={{ marginLeft: 5, color: "white" }}>Añadir Opción</span>
																					</Button>
																				</div>
																			)}
																		</FieldArray>
																	</div>
																)}

																<div style={{ display: "flex", flexDirection: "row", position: "absolute", top: 10, right: 10 }}>
																	<Button style={{ marginRight: 5, }} type="button" className="secondary" onClick={() => insert(index + 1, values?.fields[index])}>
																		<IoDuplicate />
																	</Button>
																	<Button style={{ marginRight: 5, }} type="button" className="secondary" onClick={() => swap(index, index - 1)}>
																		<IoArrowUp />
																	</Button>
																	<Button style={{ marginRight: 5, }} type="button" className="secondary" onClick={() => swap(index, index + 1)}>
																		<IoArrowDown />
																	</Button>
																	<Button type="button" className="secondary" onClick={() => remove(index)}>
																		<IoRemove />
																	</Button>
																</div>
															</div>
														);
													})
												)}

												{values.fields.length > 0 && (
													<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginBottom: 15 }}>
														<div style={{ display: "flex", flexDirection: "row" }}>
															<Button style={{ color: "white", width: 150 }} type="button" onClick={() => push({ name: "", id: "", placeholder: "", type: "input" })}>
																<IoAdd size={24} />
																<span style={{ marginLeft: 5, color: "white" }}>Añadir Campo</span>
															</Button>
														</div>
													</div>
												)}
											</div>
										);
									}}
								</FieldArray>
							</div>
						</form>
					);
				}}
			</Formik>

			<Modal ariaHideApp={false} isOpen={modal} style={customStyles} onRequestClose={() => setModal(false)} contentLabel="Example Modal">
				<h2>
					Detalles de {modal.name} {modal.lastName}
				</h2>

				<div style={{ padding: 15 }}>
					<p>Email: {modal.email}</p>
					<p>Fecha de Nacimiento: {typeof modal.birthDate === "string" ? modal.birthDate : moment(modal.birthDate?.toDate()).format("DD-MM-YYYY")}</p>
					<p>Observaciones: {modal.observations}</p>
					<p>Talla: {modal.sizing}</p>
				</div>
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button style={{ width: 150 }} onClick={() => setModal(false)}>
						Cerrar
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default CourseForm;

const Button = styled.button`
	height: 35px;
	width: 35px;
	background-color: #141b59;
	font-family: "Montserrat";
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0px;
	color: white;
	border-radius: 7px;
	align-self: center;
`;

const SearchBox = styled.input`
	font-size: 16px;
	padding-left: 20px;
	padding-right: 20px;
	font-family: "Montserrat";
`;

const FieldBox = styled.div`
	margin-bottom: 10px;
	display: flex;
	align-items: center;
`;

const Input = styled.input`
	border-radius: 5px;
	border: gainsboro 2px solid;
	height: 45px;
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
	font-family: "Montserrat";
`;

const TextArea = styled.textarea`
	border-radius: 5px;
	border: gainsboro 2px solid;
	font-size: 16px;
	font-family: "Montserrat";
	padding: 10px;
`;

const Select = styled.select`
	height: 35px;
	font-size: 16px;
	padding-left: 10px;
	padding-right: 10px;
	font-family: "Montserrat";
	border-radius: 5px;
	border: gainsboro solid 2px;
`;
