import firebase from "firebase/app";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HomeScreen = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [modalPayment, setModalPayment] = useState(false);

	useEffect(() => {
		firebase
			.firestore()
			.collection("forms")
			.where("active", "==", true)
			.orderBy("order", "asc")
			.get()
			.then((query) => {
				const d = query.docs.map((doc) => ({ ...doc.data(), id: doc.id, ref: doc.ref }));
				setData(d);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setError(true);
				setLoading(false);
			});
	}, []);

	if (loading) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<Loader type="Puff" color="#141b59" height={100} width={100} />
			</div>
		);
	}
	if (error) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<div style={{ padding: 20, marginTop: 15, maxWidth: 600, marginBottom: 15, backgroundColor: "#DFDFDF", borderRadius: 10, textAlign: "center" }}>
					<p style={{ margin: 0, fontSize: 18 }}>Ha ocurrido un error al cargar esta página, inténtelo de nuevo o contacte con email@email.com</p>
					<img src="https://pa1.narvii.com/6545/83dacee449b3e918df1b7c5c1c03176ae0878159_hq.gif" width={"auto"} height={200} style={{ margin: "0px auto" }} />
				</div>
			</div>
		);
	}
	return (
		<div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title style={{ fontFamily: "Montserrat" }}>{"CDNB | Programas Disponibles"}</title>
			</Helmet>

			<div
				style={{
					height: 250,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					position: "relative",
					backgroundImage: "url(/field.jpg)",
					backgroundPosition: "center center",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					paddingLeft: 10,
					paddingRight: 10,
					boxSizing: "border-box"
				}}
			>
				{/* <img style={{ visibility: "hidden", position:"absolute" }} src="https://images.pexels.com/photos/114296/pexels-photo-114296.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" /> */}
				<h1 style={{ color: "white", lineHeight: "1.2em", textAlign: "center", fontSize: "2.5em" }}>Club Deportivo Nuevo Boadilla</h1>
			</div>
			<div
				style={{
					minHeight: "calc(100% - 250px)",
					display: "grid",
					columnGap: 15,
					boxSizing: "border-box",
					rowGap: 15,
					gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
					padding: 15,
					maxWidth: 1200,
					width: "100%",
					alignSelf: "center",
				}}
			>
				{data.length === 0 ? (
					<div style={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center", display: "flex", flex: 1 }}>
						<div style={{ padding: 20, marginTop: 15, maxWidth: 600, marginBottom: 15, backgroundColor: "#f3f3f3", borderRadius: 10, textAlign: "center" }}>
							<p style={{ margin: 0, fontSize: 18 }}>No hay cursos disponibles en estos momentos</p>
						</div>
					</div>
				) : (
					data.map((course, i) => {
						return (
							<Link style={{ pointerEvents: course.spotsAvailable > 0 ? "auto" : "none", opacity: course.spotsAvailable > 0 ? 1 : .5 }} className="courseCard" to={`/formulario/${course.id}`} key={i}>
								<div
									style={{
										gridTemplateColumns: "repeat(4, 1fr)",
										gridTemplateRows: "repeat(2, 350px)",
										boxSizing: "border-box",
										backgroundColor: "white",
										borderRadius: 10,
										boxShadow: "rgb(0 0 0 / 10%) 0px 5px 10px 0",
										display: "flex",
										flexDirection: "column",
										overflow: "hidden",
										maxWidth: 400
									}}
								>
									<div style={{ display: "flex", flex: 1, maxHeight: 250 }}>
										<img src={course.image} width="100%" height="250px" style={{ objectFit: "cover" }} />
									</div>
									<div style={{ padding: "15px 20px" }}>
										<h2 style={{ margin: 0, fontSize: "1em" }}>{course.name}</h2>
										<div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
											<div style={{ flex: 1, }}>
												<p style={{ marginTop: 10, fontSize: 12, marginBottom: 5 }}>Plazas</p>
												<div className="numbers" style={{ backgroundColor: "#f2f2f2", padding: 15, borderRadius: 10, textAlign: "center" }}>
													{course.spotsAvailable || 0}
												</div>
											</div>
											<div style={{ flex: 1, marginLeft: 10, fontSize: 12, }}>
												<p style={{ marginTop: 10, marginBottom: 5 }}>Matrícula</p>
												<div className="numbers" style={{ backgroundColor: "#f2f2f2", padding: 15, borderRadius: 10, textAlign: "center" }}>
													{course.price || 0}€
												</div>
											</div>
											<Link to="" style={{ color: "black", textDecoration: "none", flex: 1, marginLeft: 10, fontSize: 12 }} onClick={() => setModalPayment(true)}>
												<p style={{ marginTop: 10, marginBottom: 5 }}>Mensualidad</p>
												{course.priceMonthly ?
													<div className="numbers" style={{ backgroundColor: "#f2f2f2", padding: 15, borderRadius: 10, textAlign: "center" }}>
														{course.priceMonthly || 0}€
													</div> :

													<div style={{ backgroundColor: "#f2f2f2", padding: 15, fontSize: 12, lineHeight: "14px", borderRadius: 10, textAlign: "center" }}>
														Según tabla de precios
													</div>
												}
											</Link>
										</div>
									</div>
								</div>
							</Link>
						);
					})
				)}
			</div>
			<Modal ariaHideApp={false} isOpen={modalPayment} style={customStyles} onRequestClose={() => setModalPayment(false)} contentLabel="Modal Pagos">
				<h2 style={{ fontSize: "2em", marginTop: 10 }}>
					Detalles de precios temporada
				</h2>
				<iframe src="/prices24.pdf#toolbar=0" style={{ border: "0px solid" }} width="100%" height="500px" />
				<div style={{ display: "flex", marginTop: 30, justifyContent: "space-between" }}>
					<Button style={{ width: 150, backgroundColor: "#839192" }} onClick={() => setModalPayment(false)}>
						Cerrar
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default HomeScreen;

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},

	overlay: {
		backgroundColor: "rgba(0,0,0,.7)",
	},
};

const Button = styled.button`
	height: 35px;
	width: 35px;
	background-color: #141b59;
	font-family: "Montserrat";
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0px;
	color: white;
	border-radius: 7px;
	align-self: center;
`;