const dataConsent = (
	<div>
		<h3>
			<b>CLAUSULA DE CONSENTIMIENTO PARA EL TRATAMIENTO DE DATOS PERSONALES DE MENORES</b>
		</h3>
		<p></p>
		<p>
			En relación con el tratamiento de los datos personales del menor o la menor así como de sus progenitores, tutores o representantes legales que se indican al final del
			presente documento, y de acuerdo a lo que establece el artículo 13 del Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la
			protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE:
			Reglamento general de protección de datos (DOUE L 119, de 4-5-2016), y el artículo 11 de la Ley Orgánica 3/2018, de 5 de diciembre, de protección de datos y garantía de los
			derechos digitales (BOE de 6-12-2018), se informa de lo siguiente:
		</p>
		<p>RESPONSABLE DEL TRATAMIENTO</p>
		<p>
			Federación Española de Fútbol, Plaza de Luis Aragonés, s/n<span>&nbsp; </span>28232 (Madrid)
		</p>
		<p>DATOS DE CONTACTO DEL DELEGADO DE PROTECCIóN DE DATOS</p>
		<p>
			Plaza de Luis Aragonés, s/n<span>&nbsp; </span>28232 (Madrid)
		</p>
		<p>DATOS PERSONALES QUE SE TRATARáN</p>
		<p>Datos personales del menor o la menor:</p>
		<p>1. Datos identificativos: nombre y apellidos, NIF/Pasaporte/NIE.</p>
		<p>2. Fecha de nacimiento.</p>
		<p></p>
		<p>
			Asimismo, recabamos de los progenitores, tutores o representantes legales los datos de identificación (nombre y apellidos, NIF/Pasaporte/NIE), datos de contacto
			(teléfono y correo electrónico),<span>&nbsp; </span>su relación con el/la menor.
		</p>
		<p></p>
		<p>FINALIDADES PARA LAS QUE SE TRATAN SUS DATOS</p>
		<p>
			La recopilación de datos personales tiene con la finalidad de gestionar su participación en los distintos eventos organizados por la RFEF, gestión administrativa, gestión de
			cobros y pagos, realización de encuestas de opinión, así
			<span>&nbsp; </span>como para el envío de comunicaciones comerciales, incluso por medios electrónicos, sobre los distintos cursos, actividades y eventos organizados por la
			RFEF.
		</p>
		<p>
			Los datos de contacto de las personas mayores de edad únicamente serán tratados en caso de que fuese necesario para la gestión de cuestiones directamente relacionadas con el
			consentimiento otorgado.
		</p>
		<p></p>
		<p>BASE JURIDICA DEL TRATAMIENTO DE SUS DATOS</p>
		<p>
			La base jurídica del tratamiento de sus datos es su consentimiento.<span>&nbsp;</span>
		</p>
		<p>TRANSFERENCIAS INTERNACIONALES DE DATOS</p>
		<p>
			Los datos personales podrán ser transferidos a un tercer país o a una organización internacional en caso de que sea necesario para la finalidad perseguida. En todo caso se
			garantizará el cumplimiento de las disposiciones del Reglamento General de Protección de Datos y de la Ley Orgánica de Protección de Datos y Garantía de los Derechos
			Digitales.
		</p>
		<p>DERECHO A RETIRAR SU CONSENTIMIENTO</p>
		<p>Podrá retirar su consentimiento en cualquier momento. En este caso, sin embargo, podría ser imposible seguir manteniendo la relación de la RFEF con el/la menor.</p>
		<p>DESTINATARIOS A LOS QUE SE COMUNICARAN LOS DATOS</p>
		<p>
			La Real Federación Española de Fútbol no comunicará sus datos a terceros, salvo que sea imprescindible para la finalidad del tratamiento o previo requerimiento judicial o
			petición del Ministerio Fiscal o de las Administraciones Públicas en el ejercicio de sus potestades.
		</p>
		<p>PLAZO DE CONSERVACIóN DE SUS DATOS</p>
		<p>
			Los datos serán conservados durante el tiempo imprescindible en función de la finalidad para la que fueron facilitados o recabados, sin perjuicio de, en su caso, el ejercicio
			de su derecho de supresión.
		</p>
		<p>DERECHOS DE PROTECCIóN DE DATOS</p>
		<p>Podrá ejercer los derechos siguientes:</p>
		<p>
			Acceso, rectificación, supresión, oposición, portabilidad de los datos, derecho a no ser objeto de una decisión basada en el tratamiento automatizado de los datos, limitación
			del tratamiento de los datos.
		</p>
		<p>
			Para ejercer estos derechos podrá dirigirse por escrito, acreditando su identidad, a c/ Ramón y Cajal s/n, 28230 Las Rozas (Madrid) Responsable de Seguridad, área de
			Tecnología e Infraestructuras.
		</p>
		<p>DERECHO A PRESENTAR UNA RECLAMACIóN ANTE LA AGENCIA ESPAñOLA DE PROTECCIóN DE DATOS.</p>
		<p>
			Si considera que el tratamiento de datos personales que le conciernen infringe la normativa en vigor sobre protección de datos personales puede presentar una reclamación ante
			la Agencia Española de Protección de Datos, calle Jorge Juan, 6, 28001 Madrid (
			<a href="http://www.aepd.es/">
				<span>www.aepd.es</span>
			</a>
			)
		</p>
		<p></p>
		<p>TRATAMIENTO DE IMAGENES</p>
		<p>
			De acuerdo con Ley Orgánica 1/1982, de 5 de mayo, de protección civil del derecho al honor, a la intimidad personal y familiar y a la propia imagen, al marcar la casilla
			autorizo que las imágenes captadas del menor o la menor puedan ser utilizadas en redes sociales, medios de comunicación o en la Web de la RFEF con la finalidad de dar a
			conocer y promocionar las actividades en que participe el menor o la menor, con pleno respeto a sus derechos.
		</p>
		<p></p>
		<p>AUTORIZANDO el tratamiento de las imágenes.</p>
		<p></p>
		<p>Al AUTORIZAR el presente documento OTORGAN SU CONSENTIMIENTO EXPRESO para el tratamiento de sus datos personales en los términos en él contenidos</p>
	</div>
);

const responsability = (
	<div>
		<p>
			Por medio del presente <strong>AUTORIZO Y DECLARO:</strong>
		</p>
		<p>
			Mediante este documento a la Real Federación Española de Fútbol que el alumno no padece enfermedad o discapacidad física o psíquica por las cuales no pueda participar en el
			Curso de Tecnificación de la Escuela de la RFEF, el cual dará comienzo en octubre y finalizará en mayo. En adelante, el “curso”
		</p>

		<p>
			Que, conforme a lo anterior, la Real Federación Española de Fútbol no será responsable por los daños derivados de cualquier tipo de actividad fruto de la participación del
			alumno en el Curso, renunciando expresamente por medio del presente documento a exigir responsabilidad alguna por las eventuales lesiones que pudieran derivarse como
			consecuencia de la practica ordinaria de la actividad deportiva del Curso.
		</p>

		<p>
			Que, por medio de la presente autorización doy mi expreso consentimiento para que el alumno reciba el tratamiento médico que pueda considerarse conveniente en caso de lesión,
			accidente y/o enfermedad durante el desarrollo del Curso, incluyendo, aquellas decisiones médico-quirúrgicas que, en caso de extrema urgencia y en las que no quepa consulta
			previa, fuese necesario adoptar, todo ello bajo la adecuada prescripción facultativa que correspondiere.
		</p>
	</div>
);

const imageConsent = (
	<div>
		<p>
			Por medio del presente <b>AUTORIZO Y DECLARO:</b>
		</p>
		<p>
			De acuerdo con Ley Orgánica 1/1982, de 5 de mayo, de protección civil del derecho al honor, a la intimidad personal y familiar y a la propia imagen, autorizo a
			la Real Federación Española de Fútbol a que utilice total o parcialmente de manera indefinidahasta revocación expresa, sin límite
			geográfico,en exclusiva y con facultad de cesión a terceros, la imagen y/o sonido del Alumno (incluido nombre, apellidos, y voz) como consecuencia de su
			participación en el Curso de Tecnificación de la Escuela de la RFEF, el cual dará comienzo el día XXXXXXXX y finalizará el día
			XXXXXXXXX a fin de que la misma sea divulgada en acciones promocionales y académicas de la RFEF por cualquier medio, incluida su página web y redes sociales.
		</p>
		<p>
			La presente autorización es de carácter gratuito y no se encuentra sometida a ningún plazo temporal ni restringida a ningún ámbito
			geográfico determinado, salvo lo dispuesto legalmente.
		</p>
		<p></p>
		<p>
			*Conforme a lo dispuesto en el art. 13 del Reglamento (UE) 2016/679, de Protección de Datos y en el art. 11 de la Ley Orgánica 3/2018, de Protección de
			Datos, se informa que los datos facilitados a través del presente formulario serán tratados por Real Federación Española de Fútbol, , para
			las finalidades que se desprenden del presente documento, teniendo este por objeto llevar a cabo la gestión de la autorización de la cesión de derechos
			de imagen, datos personales y derechos de propiedad intelectual. La base de legitimación del tratamiento de sus datos se encuentra en el consentimiento expreso e
			inequívoco manifestado por el tutor o representante legal en caso de tratarse de menores de edad. Los datos se conservarán durante el tiempo necesario para
			cumplir con la finalidad para la que se recabaron y para determinar las posibles responsabilidades que se pudieran derivar de dicha finalidad y del tratamiento de los datos.
			A los mismos efectos anteriores, garantiza que todos los datos personales contenidos en el presente documento puedan ser tratados, y consecuentemente, cedidos a los efectos
			de que pueda garantizarse la presente autorización sobre cesión de derechos de imagen. Para ejercitar los derechos de Acceso, Rectificación,
			Supresión, Limitación, Portabilidad o, en su caso, Oposición, no ser objeto de una decisión basada en el tratamiento automatizado de los datos o
			limitación del tratamiento, dirigiéndose por escrito, acreditando su identidad, a Plaza Luis Aragonés s/n, 28232 Las Rozas (Madrid) Responsable de
			Seguridad, área de Tecnología e Infraestructuras. o a la siguiente dirección de correo electrónico{" "}
			<a href="mailto:protecciondatos@rfef.es">
				<span>protecciondatos@rfef.es</span>
			</a>{" "}
			. En todo caso, podrá, igualmente, formular reclamaciones antela Agencia Española de Protección de datos (https://www.aepd.es/)
		</p>
	</div>
);

const datapolicy = (
	<div style={{ maxWidth: 1200, alignSelf: "center" }}>
		<p class="p1">Por medio de la presente y de acuerdo al Reglamento General de Protección de Datos relativo a la protección de las personas físicas&nbsp; en lo que respecta al tratamiento de datos personales y a la libre circulación&nbsp; de estos datos, le facilitamos la siguiente información detallada del&nbsp; tratamiento de datos personales y comunicaciones:</p>
		<p class="p2">&nbsp;</p>
		<p class="p3"><strong>Responsable del tratamiento: </strong>Sus datos pasarán a formar&nbsp; parte de un fichero titularidad de CLUB DEPORTIVO ELEMENTAL NUEVO BOADILLA (en adelante CDNB), con CIF G85528511 y domicilio en Crtra Boadilla a Pozuelo Km 1,4- Polideportivo Municipal Angel Nieto. Campo 2, 28660 - Boadilla del Monte.</p>
		<p class="p3"><strong>Finalidad del tratamiento: </strong>Desarrollar&nbsp; y cumplir con las obligaciones previstas en el contrato, o relación&nbsp; jurídico-negocial, que le vincula con CDNB.</p>
		<p class="p3"><strong>Conservación de datos: </strong>Sus datos serán conservados&nbsp; durante el periodo mínimo de cinco años.</p>
		<p class="p3"><strong>Legitimación: </strong>La legitimación para la recogida de sus datos se basa en el contrato suscrito o en su relación jurídico-negocial con CDNB.</p>
		<p class="p3"><strong>Destinatarios: </strong>Sus datos no serán cedidos para otras finalidades distintas a las&nbsp; anteriormente descritas, salvo obligación legal, si bien podrán ser&nbsp; transmitidos a los proveedores de servicios que estén vinculados por contrato o encargo de tratamiento con CDNB.</p>
		<p class="p3"><strong>Derechos: </strong>Puede ejercer sus derechos de acceso, rectificación, cancelación,&nbsp; limitación, portabilidad y oposición al tratamiento de sus datos cuando se den&nbsp; determinadas circunstancias, en cuyo caso únicamente serán conservados para el&nbsp; cumplimiento de las obligaciones legalmente previstas.</p>
		<p class="p1">Para ejercer los derechos&nbsp; anteriormente descritos deberá dirigirse a CDNB, con CIF G85528511 y domicilio en Crtra Boadilla a Pozuelo Km 1,4- Polideportivo Municipal Angel Nieto. Campo 2, 28660 - Boadilla del Monte (Madrid). De igual modo, le informamos de que la Agencia Española de Protección de&nbsp; Datos es el órgano competente destinado a la tutela de estos derechos.</p>
		<p class="p1">Con la finalidad de mantener actualizados los datos, el cliente deberá&nbsp; comunicar cualquier cambio que se produzca sobre los mismos.</p>
		<p class="p4">&nbsp;</p>
		<p class="p3"><strong>Compromiso de confidencialidad: </strong>De igual&nbsp; modo, de acuerdo con lo dispuesto por el RGPD, relativo al deber de secreto&nbsp; profesional, CDNB se compromete a guardar la confidencialidad de&nbsp; los datos de carácter personal, subsistiendo esta obligación con posterioridad&nbsp; a la finalización, por cualquier causa, de la relación entre Usted y CDNB.<strong>&nbsp;</strong></p>
		<p class="p3"><strong>ACEPTO </strong>que CDNB me remita comunicaciones informativas a través de&nbsp; e-mail, SMS, o sistemas de mensajería instantánea como Whatsapp u otras, con el&nbsp; objetivo de mantenerme informado/a del desarrollo de las actividades propias&nbsp; del servicio contratado, así como la inclusión de mi teléfono en la base de datos del Club, y autorizo ser incluido en el Grupo de Whatsapp u otros similares del equipo en el que participe mi hijo/a a efectos de convocatorias a partidos o eventos, entrenamientos, comunicaciones del entrenador, entendiendo que en dicho Grupo estarán incluidos otros participantes, tutores, y el entrenador del equipo de mi hijo/a.</p>
		<p class="p3">* &nbsp;Todo lo recogido en este documento se entiende aplicable a quien suscribe y a su hijo/a, tutelado/a</p>
	</div >
)

const proteccionDatos = (
	<div>
		<p class="p1"><strong>CLÁUSULA DE DEBER DE INFORMACIÓN DE PROTECCION DE DATOS PARA DEPORTISTAS DEL CLUB DEPORTIVO NUEVO BOADILLA</strong></p>
		<p class="p1">En cumplimiento de lo dispuesto en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, EL CLUB DEPORTIVO NUEVO BOADILLA, le informa de los siguientes aspectos:</p>
		<p class="p1">CLUB DEPORTIVO NUEVO BOADILLA es responsable de un fichero de datos de carácter personal, en el cual se encuentran incluidos los datos de los deportistas inscritos en el Club y de los progenitores o tutores de los mismos para el supuesto de ser estos menores de edad. En concreto, en dicho fichero serán incluidos sus datos personales y, en su caso, grabaciones de eventos y competiciones deportivas o celebraciones del Club, así como fotografías necesarias para tramitar la ficha federativa, carnet o identificación interna para el Club.</p>
		<p class="p1">Su finalidad es permitir la selección de deportistas, la comunicación con los mismos, localización a progenitores y/o tutores, envío de información referente al Club, así como la inscripción en competiciones oficiales o extraoficiales en las que participe o pretenda participar el CLUB DEPORTIVO NUEVO BOADILLA.</p>
		<p class="p1">En este sentido, a través de la firma de esta cláusula, usted consiente el tratamiento de sus datos personales e imágenes, y a que los mismos (con excepción de aquellos datos personales especialmente protegidos) puedan ser publicados en la página web del Club, o en publicaciones, entrevistas o retransmisiones, con el fin de permitir y facilitar la celebración y participación en eventos deportivos o socio- culturales por su parte o del representado siempre en nombre del Club.</p>
		<p class="p1">Por otro lado, el CLUB DEPORTIVO NUEVO BOADILLA podrá ceder sus datos (con excepción igualmente de aquellos datos personales contemplados en la misma Ley como especialmente protegidos) a las Asociaciones, Organismos Oficiales y Extraoficiales, Administración Pública, Federaciones y otros Clubes o Asociaciones deportivas con los fines de participar en competiciones y eventos.</p>
		<p class="p1">Igualmente le advertimos de su derecho a no prestar su consentimiento al tratamiento de los datos sobre su ideología, religión o creencias. Entenderemos que con la firma de esta cláusula NO otorga este consentimiento en caso de no manifestar usted expresamente lo contrario por escrito.</p>
		<p class="p1">En el caso de que facilite al CLUB DEPORTIVO NUEVO BOADILLA datos personales de terceras personas (familiares, pareja, amigos, etc.), mediante la firma de esta cláusula, usted garantiza que ha informado previamente de ello a estas personas y que se encuentra autorizado para hacerlo, en las mismas condiciones y para las mismas finalidades previstas en el presente documento.</p>
		<p class="p1">Asimismo, el CLUB DEPORTIVO NUEVO BOADILLA le informa de que, en el caso de que siendo usted, o el menor que representa, un deportista del Club, el deportista deberá someterse, en su caso, a una serie de reconocimientos médicos con carácter previo y obligatorios, para poder federarle y causar alta en la Mutualidad de futbolistas de la Federación de Fútbol de Madrid), en su caso. Por ello, le solicitamos su consentimiento expreso para la obtención y tratamiento de sus datos de salud, con el fin de saber su aptitud para participar como deportista en competiciones oficiales y extraoficiales, así como para poder hacer un seguimiento de su salud durante su permanencia en el Club en su caso, garantizándole la máxima confidencialidad en el tratamiento de los mismos.
			<br />Asimismo, el CLUB DEPORTIVO NUEVO BOADILLA, tiene contratado un Seguro de Responsabilidad Civil para todo el Colectivo del Club, y podría contratar seguros de accidentes o asistencia sanitaria por lesiones para su Colectivo de jugadores, para lo cual, y en caso de siniestro que le afecte, deberá ceder los datos del jugador afectado a la entidad Aseguradora en caso de haberse contratado dicho seguro, para lo cual se le solicita su autorización con la firma de este documento pues en otro caso no recibiría asistencia de dicho seguro de haberse contratado.</p>
		<p class="p1">El CLUB DEPORTIVO NUEVO BOADILLA le garantiza la confidencialidad de los datos personales aportados y en respuesta a su preocupación por garantizar la seguridad y confidencialidad de los mismos, ha adoptado los niveles de seguridad requeridos de protección de los datos personales y ha instalado los medios técnicos a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales facilitados al CLUB DEPORTIVO NUEVO BOADILLA.</p>
		<p class="p1">Igualmente, le rogamos nos comunique cualquier cambio que puedan sufrir los datos facilitados, a fin de poder tenerlos siempre actualizados.</p>
		<p class="p1">El CLUB DEPORTIVO NUEVO BOADILLA le informa que podrá ejercitar los derechos de acceso, rectificación, cancelación u oposición en la dirección indicada en el encabezamiento mediante escrito identificado con la referencia “Protección de Datos”, en el que se concrete su solicitud y al que acompañe fotocopia de su Documento Nacional de Identidad.</p>
	</div>
)

const sports = (
	<div>
		<p class="p1"><strong>CONSENTIMIENTO INFORMADO PARA LA PRÁCTICA DE DEPORTE POR MENORES DE EDAD</strong></p>
		<p class="p1">El presente documento tiene como fin, poner en conocimiento de los padres o tutores de los deportistas menores de edad que se inscriben en el CLUB DEPORTIVO NUEVO BOADILLA sobre las obligaciones y deberes de cada una de las partes, así como la eventual responsabilidad civil, contractual y extracontractual con ocasión de la práctica del deporte por parte de los menores, tanto en entrenamientos, como en partidos de fútbol de competición oficial, extraoficial, amistosos y torneos.</p>
		<p class="p1">La práctica de deporte conlleva siempre un riesgo de lesión o daño, por ello el CLUB DEPORTIVO NUEVO BOADILLA pone en conocimiento de los padres y/o tutores de los menores dichos riesgos, generalmente los asociados con fracturas óseas, rotura de ligamentos, lesiones musculosas, golpes, caídas, esguinces, insuficiencias respiratorias, y riesgos comúnmente asociados con la práctica del deporte en equipo, por lo tanto los padres y/o tutores deciden libre y espontáneamente que su hijo o tutelado, pueda practicar fútbol y actividad física con el CLUB DEPORTIVO NUEVO BOADILLA.</p>
		<p class="p1">Los padres y/o tutores comprenden y entienden las estipulaciones e indicaciones consagradas en el presente documento con el fin de prever cualquier eventualidad y tener clara la responsabilidad de cada una de las partes.</p>
		<p class="p1">Entre el CLUB DEPORTIVO NUEVO BOADILLA y los padres y/o tutores del menor de edad, se celebra el presente acuerdo de voluntades por medio del cual se exime de la responsabilidad civil contractual o extracontractual, surgida en virtud de la prestación de servicios por parte del CLUB DEPORTIVO NUEVO BOADILLA y a la reclamación de indemnización por lesiones que pueda sufrir el menor de edad por la práctica de la actividad de fútbol:</p>
		<p class="p1"><strong>CLAUSULA PRIMERA:</strong>&nbsp;Partes: CLUB DEPORTIVO NUEVO BOADILLA, se entiende que suministra el material y los recursos necesarios para el desarrollo de la actividad deportiva, entiéndase monitores, instructores y material para la práctica de fútbol (excluida equipación de entrenamiento y de paseo), siendo el terreno de juego e instalaciones donde se practica propiedad del Ayuntamiento de Boadilla del Monte o de terceros arrendadas por el Club; por otra parte, se entiende que es PARTICIPANTE quien toma los servicios de práctica de fútbol.</p>
		<p class="p1"><strong>CLAUSULA SEGUNDA:</strong>&nbsp;Aceptación de la responsabilidad de riesgo: Los padres o tutores del menor (participante) declara saber que la práctica del fútbol y todos los deportes son actividades que tienen un riesgo e implican un peligro inherente a dichas actividades, que no puede ser completamente eliminado aún cuando exista algún grado de prevención, cuidados, precaución, instrucción o experiencia. Los representantes del menor participante conocen y entienden la gama de naturaleza y amplitud de los riesgos que envuelve la práctica del fútbol. En este deporte pueden presentarse imprevistos de los cuales no puede derivarse responsabilidad a ninguna de las partes, debido a la naturaleza fortuita y conocida por los representantes del menor participante. Los representantes del menor participante aceptan los riesgos y posibles consecuencias y asumen voluntaria y libremente la responsabilidad por todos los riegos y peligros que conlleva la práctica del fútbol.</p>
		<p class="p1"><strong>CLAUSULA TERCERA:</strong>&nbsp;Responsabilidad: Los representantes del menor, por medio de este contrato descargan y liberan al CLUB DEPORTIVO NUEVO BOADILLA de toda responsabilidad civil contractual o extracontractual o pena por cualquier daño o lesión, muerte, perjuicio natural, moral o económico, derivado de esta actividad deportiva. Así mismo renuncia a todo tipo de cobro de indemnización, reclamo, demanda o acción por perjuicios derivados de las actividades acordadas y cualquier otro acto que se efectue durante el desarrollo de estas actividades o deportes.</p>
		<p class="p1"><strong>CLAUSULA CUARTA:</strong>&nbsp;Este documento entrará en vigor desde el momento en que se firme y continuará en vigencia por todo el tiempo en que el MENOR PARTICIPANTE realice las actividades propias descritas, sea de forma interrumpida o discontinua, de tal manera que no será necesario firmar nuevamente un documento similar cada vez que exista una relación deportiva entre EL MENOR PARTICIPANTE y el CLUB DEPORTIVO NUEVO BOADILLA.</p>
		<p class="p1"><strong>CLAUSULA QUINTA:</strong>&nbsp;Es responsabilidad de los padres y/o tutores la entrega y recogida del menor de edad en nuestras instalaciones, entendiendo con ello que el Club Deportivo Nuevo Boadilla en ningún caso ejerce tutoría, ni guarda, ni custodia de los menores, siendo los progenitores o tutores los responsables de permanecer en el centro donde se desarrolla la actividad para la entrega y recogida del menor desde el inicio hasta la finalización de la actividad, no existiendo, por lo tanto, servicio de entrega ni de recogida de los menores por parte del Club.</p>
		<p class="p1"><strong>CLAUSULA SEXTA:</strong>&nbsp;Cuando al MENOR PARTICIPANTE tuviera o le sobreveviniera alguna enfermedad o dolencia que no permita practicar el deporte del fútbol, o lo oculte dolosamente, o sus representantes lo ocultasen al CLUB DEPORTIVO NUEVO BOADILLA, éste no se hace responsable de las consecuencias que se generen por la práctica de estas actividades; se entiende que para la prácticas de las actividades, el menor participante se encuentra en sano juicio mental y físico y no tiene ningún impedimento para la práctica del deporte en el que se inscribe.</p>
		<p class="p1">Los padres y/o tutores firman este documento en representación del menor, asumiendo después de leerlo y entenderlo en su totalidad, en su calidad de padres o tutores, cualquier responsabilidad por la práctica que su hijo realice en alguna de las actividades aquí mencionadas, firmando de forma libre y voluntaria, y eximiendo al CLUB DEPORTIVO NUEVO BOADILLA de responsabilidad por lesiones, daños o secuelas por la práctica del fútbol por parte del menor.</p>
		<p class="p1">Sus datos de carácter personal han sido recogidos de acuerdo con lo dispuesto en la Ley Orgánica, de Protección de Datos de Carácter Personal, y se encuentran almacenados en un fichero propiedad de CLUB DEPORTIVO ELEMENTAL NUEVO BOADILLA. De acuerdo con la Ley anterior, tiene derecho a ejercer los derechos de acceso, rectificación, cancelación y oposición de los datos en la siguiente dirección info@nuevoboadilla.es.</p>
	</div>
)

export default {
	dataConsent,
	responsability,
	imageConsent,
	datapolicy,
	proteccionDatos,
	sports
};
