import firebase from "firebase/app";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import slugify from "slugify";

const FormPayment = () => {
	const file1Ref = useRef()
	const file2Ref = useRef()
	const file3Ref = useRef()
	const file4Ref = useRef()
	const file5Ref = useRef()
	const { id, formId } = useParams();
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [files, setFiles] = useState({
		payment: null,
		dniPlayer: null,
		familyBook: null,
		registration: null,
		others: null
	});
	const [uploading, setUploading] = useState(false);
	const [errorUploading, setErrorUploading] = useState(false);
	const [sending, setSending] = useState(false);
	const [errorSending, setErrorSending] = useState(false);
	const [completed, setCompleted] = useState(false);



	const loadFile = async (file, field) => {
		try {

			setUploading(field);

			const name = slugify(file.name.replace(/\.[^/.]+$/, ""));
			const format = slugify(file.name.split(".")[1]);

			var storageRef = firebase.storage().ref().child(`forms/${formId}/${name}-${new Date().valueOf()}.${format}`);
			storageRef
				.put(file)
				.then(async (snapshot) => {
					// console.log(snapshot.ref.fullPath)
					// const d = await firebase
					// 	.storage()
					// 	.ref(snapshot.ref.fullPath)
						// .getDownloadURL()
						// .then((res) => res)
						// .catch((err) => "");

					setFiles({ ...files, [field]: { uri: snapshot.ref.fullPath, name: name, date: new Date() } });
					setUploading(false);
				})
				.catch((err) => {
					setErrorUploading(true);
					setUploading(false);
				});
		} catch (err) {
			console.log(err)
			setUploading(false);
		}

		// data.on(
		//     'state_changed',
		//     (snapshot) => {
		//         const progress = parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
		//         console.log(progress)
		//         // setUploading({
		//         //     uploading: true,
		//         //     progress
		//         // })
		//     },
		//     function (error) {
		//         // Handle unsuccessful uploads
		//     },
		//     async function () {
		//         // setUploading({ uploading: false })
		//     }
		// );

		// return data;
	}

	useEffect(() => {
		firebase
			.firestore()
			.collection("forms")
			.doc(formId)
			.get()
			.then((res) => {
				if (!res.exists) {
					setLoading(false);
					setError(true);
				} else {
					setData({ ...res.data(), ref: res.ref, id: res.id });
					setLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setError(true);
			});
		return () => null;
	}, []);

	const sendReceip = () => {
		if (files) {

			setSending(true);

			const sendDocument = firebase.app().functions("europe-west1").httpsCallable('sendDocument');
			sendDocument({
				form: data.id,
				id: id,
				files,
			}).then((res) => {
				setSending(false);
				setCompleted(true);
			})
				.catch((err) => {
					console.log(err);
					setSending(false);
					setErrorSending(true);
					alert("Ocurrió un problema enviando el formaulario, intentelo mas tarde");
				});
		} else {
			alert("Debes cargar un archivo válido antes de enviarlo");
		}
	};

	if (loading) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<Loader type="Puff" color="#141b59" height={100} width={100} />
			</div>
		);
	}
	if (error || !id || !formId) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<div style={{ padding: 20, marginTop: 15, maxWidth: 600, marginBottom: 15, backgroundColor: "#DFDFDF", borderRadius: 10, textAlign: "center" }}>
					<p style={{ margin: 0, fontSize: 18 }}>Ha ocurrido un error al cargar este formulario o el enlace que ha seguido ha expirado, inténtelo de nuevo o contacte con info@nuevoboadilla.es</p>
					<img src="https://pa1.narvii.com/6545/83dacee449b3e918df1b7c5c1c03176ae0878159_hq.gif" width={"auto"} height={200} style={{ margin: "0px auto" }} />
				</div>
			</div>
		);
	}
	return (
		<div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{data.name || "Formulario Club Deportivo Nuevo Boadilla"}</title>
			</Helmet>

			<img src={data.image} width="100%" height={300} style={{ objectFit: "cover", border: 0 }} />
			<div style={{ padding: "15px 20px", width: "100%", flex: 1, display: "flex", flexDirection: "column", maxWidth: 960, alignSelf: "center", boxSizing: "border-box" }}>
				<h1 style={{ margin: 0, fontSize: "2em", marginTop: 15, marginBottom: 15, textAlign: "center" }}>{data.name}</h1>
				<div style={{ padding: 20, marginTop: 15, marginBottom: 15, backgroundColor: "#f2f2f2", borderRadius: 10 }}>
					{!completed ? (
						<div>
							<p style={{ margin: 0 }}>{data.paymentDetails}</p>

							<div style={{
								padding: "20px 10px",
							}}>
								<div style={{ display: "flex", flexDirection: "column", }}>
									<label>Justificante de Pago <small>(Adjuntar justificante de pago)</small> <small style={{ color: "red" }}>*Obligatorio</small></label>
									<div style={{ marginTop: 10, borderRadius: 5, overflow: "hidden", marginBottom: 10, display: "flex", cursor: "pointer" }}>
										<div onClick={() => file1Ref.current.click()} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 15, paddingRight: 15, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, background: "white" }}>
											{files.payment !== null ?
												<svg style={{ width: 25, height: 25, color: "green" }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
													<path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
												</svg>
												:
												<svg style={{ width: 25, height: 25 }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
													<path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
												</svg>
											}
										</div>
										<div onClick={() => file1Ref.current.click()} style={{ width: "100%", background: "white" }}>
											<p style={{ fontSize: 14 }}>{uploading === "payment" ? "Cargando archivo..." : files.payment !== null ? files.payment.name : "Haz click para cargar un archivo..."}</p>
										</div>
										<div onClick={() => setFiles({ ...files, payment: null })} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 15, paddingRight: 15, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, background: "white" }}>
											{files.payment !== null ?
												<svg style={{ width: 25, height: 25 }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
													<path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
												</svg>
												:
												null
											}
										</div>
										<input ref={file1Ref} style={{ marginTop: 15, display: "none" }} type="file" onChange={(e) => loadFile(e.target.files[0], "payment")} />
									</div>
								</div>

								<div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
									<label>DNI del Jugador <small>(Cargar imagen del DNI por las dos caras si se dispone de el)</small></label>
									<div style={{ marginTop: 10, display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
										<div style={{ borderRadius: 5, overflow: "hidden", marginBottom: 10, display: "flex", cursor: "pointer" }}>
											<div onClick={() => file2Ref.current.click()} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 15, paddingRight: 15, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, background: "white" }}>
												{files.dniPlayer !== null ?
													<svg style={{ width: 25, height: 25, color: "green" }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
													</svg>
													:
													<svg style={{ width: 25, height: 25 }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
													</svg>
												}
											</div>
											<div onClick={() => file2Ref.current.click()} style={{ width: "100%", background: "white" }}>
												<p style={{ fontSize: 14 }}>{uploading === "dniPlayer" ? "Cargando archivo..." : files.dniPlayer !== null ? files.dniPlayer?.name : "Haz click para cargar un archivo..."}</p>
											</div>
											<div onClick={() => setFiles({ ...files, dniPlayer: null })} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 15, paddingRight: 15, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, background: "white" }}>
												{files.dniPlayer !== null ?
													<svg style={{ width: 25, height: 25 }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
													</svg>
													:
													null
												}
											</div>
											<input ref={file2Ref} style={{ marginTop: 15, display: "none" }} type="file" onChange={(e) => loadFile(e.target.files[0], "dniPlayer")} />
										</div>
									</div>
								</div>

								<div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
									<label>Libro de Familia <small>(Cargar documento si se dispone de el)</small></label>
									<div style={{ marginTop: 10, display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>


										<div style={{ borderRadius: 5, overflow: "hidden", marginBottom: 10, display: "flex", cursor: "pointer" }}>
											<div onClick={() => file3Ref.current.click()} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 15, paddingRight: 15, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, background: "white" }}>
												{files.familyBook !== null ?
													<svg style={{ width: 25, height: 25, color: "green" }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
													</svg>
													:
													<svg style={{ width: 25, height: 25 }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
													</svg>
												}
											</div>
											<div onClick={() => file3Ref.current.click()} style={{ width: "100%", background: "white" }}>
												<p style={{ fontSize: 14 }}>{uploading === "familyBook" ? "Cargando archivo..." : files.familyBook !== null ? files.familyBook?.name : "Haz click para cargar un archivo..."}</p>
											</div>
											<div onClick={() => setFiles({ ...files, familyBook: null })} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 15, paddingRight: 15, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, background: "white" }}>
												{files.familyBook !== null ?
													<svg style={{ width: 25, height: 25 }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
													</svg>
													:
													null
												}
											</div>
											<input ref={file3Ref} style={{ marginTop: 15, display: "none" }} type="file" onChange={(e) => loadFile(e.target.files[0], "familyBook")} />
										</div>

									</div>
								</div>
								<div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
									<label>Certificado de Empadronamiento <small>(Cargar documento si se dispone de el)</small></label>
									<div style={{ marginTop: 10, display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>


										<div style={{ borderRadius: 5, overflow: "hidden", marginBottom: 10, display: "flex", cursor: "pointer" }}>
											<div onClick={() => file4Ref.current.click()} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 15, paddingRight: 15, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, background: "white" }}>
												{files.registration !== null ?
													<svg style={{ width: 25, height: 25, color: "green" }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
													</svg>
													:
													<svg style={{ width: 25, height: 25 }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
													</svg>
												}
											</div>
											<div onClick={() => file4Ref.current.click()} style={{ width: "100%", background: "white" }}>
												<p style={{ fontSize: 14 }}>{uploading === "registration" ? "Cargando archivo..." : files.registration !== null ? files.registration?.name : "Haz click para cargar un archivo..."}</p>
											</div>
											<div onClick={() => setFiles({ ...files, registration: null })} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 15, paddingRight: 15, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, background: "white" }}>
												{files.registration !== null ?
													<svg style={{ width: 25, height: 25 }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
													</svg>
													:
													null
												}
											</div>
											<input ref={file4Ref} style={{ marginTop: 15, display: "none" }} type="file" onChange={(e) => loadFile(e.target.files[0], "registration")} />
										</div>

									</div>
								</div>
								<div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
									<label>Otros Documentos</label>
									<div style={{ marginTop: 10, display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>


										<div style={{ borderRadius: 5, overflow: "hidden", marginBottom: 10, display: "flex", cursor: "pointer" }}>
											<div onClick={() => file5Ref.current.click()} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 15, paddingRight: 15, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, background: "white" }}>
												{files.others !== null ?
													<svg style={{ width: 25, height: 25, color: "green" }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
													</svg>
													:
													<svg style={{ width: 25, height: 25 }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
													</svg>
												}
											</div>
											<div onClick={() => file5Ref.current.click()} style={{ width: "100%", background: "white" }}>
												<p style={{ fontSize: 14 }}>{uploading === "others" ? "Cargando archivo..." : files.others !== null ? files.others?.name : "Haz click para cargar un archivo..."}</p>
											</div>
											<div onClick={() => setFiles({ ...files, others: null })} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 15, paddingRight: 15, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, background: "white" }}>
												{files.others !== null ?
													<svg style={{ width: 25, height: 25 }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
														<path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
													</svg>
													:
													null
												}
											</div>
											<input ref={file5Ref} style={{ marginTop: 15, display: "none" }} type="file" onChange={(e) => loadFile(e.target.files[0], "others")} />
										</div>

									</div>
								</div>

							</div>
							<div style={{ display: "flex", justifyContent: "flex-end" }}>
								<button
									disabled={!files.payment}
									onClick={sendReceip}
									style={{
										opacity: (!files.payment) ? .5 : 1,
										height: 50,
										padding: "0px 40px",
										marginTop: 20,
										display: "flex",
										width: "100%",
										backgroundColor: "#141b59",
										border: 0,
										borderRadius: 5,
										alignItems: "center",
										justifyContent: "center",
										color: "white",
										fontSize: 16,
										fontFamily: "Montserrat, sans-serif",
										textDecoration: "none",
										cursor: (!files.payment) ? "not-allowed" : "pointer"
									}}
								>
									{sending ? "Enviando..." : "Enviar Documentos"}
								</button>
							</div>
						</div>
					) : (
						<div>
							<p style={{ margin: 0 }}>{data.postPaymentMessage || "Se ha enviado con éxito el formulario, en cuanto confirmemos el pago te haremos llegar un correo electrónico confirmandote que tu inscripción esta completada."}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default FormPayment;
