import React from "react";
import { Box, Button, Layer } from "grommet";
import documentsData from "./documentsData"

const AgreementModal = ({ open, acceptCallback, cancelCallback, content }) => {
	return (
		<>
			{open && (
				<Layer>
					<Box pad="medium" overflow={{ vertical: "auto" }} height="medium">
						{content === "datapolicy" ?
							documentsData["datapolicy"]
							:
							content === "consentimientoDeporte" ?
								documentsData["sports"]
								// <iframe src="https://firebasestorage.googleapis.com/v0/b/nuevo-boadilla.appspot.com/o/CONSENTIMIENTO%20PRACTICA%20DE%20MENORES.pdf?alt=media&token=be06fd75-8a09-4801-b2e7-e9a486a7f0ac" style={{ border: "0px solid", minWidth: 350 }} width="100%" height="500px" />
								:
								content === "dataProtection" ?
									documentsData["proteccionDatos"]
									// <iframe src="https://firebasestorage.googleapis.com/v0/b/nuevo-boadilla.appspot.com/o/PROTECCION%20DE%20DATOS.pdf?alt=media&token=fbc969f2-c77f-4dd9-8cbe-2b1bcf79129a" style={{ border: "0px solid", minWidth: 350 }} width="100%" height="500px" />
									: null
						}
					</Box>
					<Box direction="row" align="center" justify="between" pad="medium">
						<Button label="Rechazar" onClick={cancelCallback} primary color="#f3f3f3" style={{ borderRadius: 7 }} />
						<Button label="Aceptar" onClick={acceptCallback} style={{ color: "white", borderRadius: 7 }} primary color="status-ok" />
					</Box>
				</Layer>
			)}
		</>
	);
};

export default AgreementModal;
