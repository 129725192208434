import firebase from "firebase/app";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { IoEye, IoLink, IoTrash } from "react-icons/io5";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import styled from "styled-components";
import XLSX from 'xlsx';


const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},

	overlay: {
		backgroundColor: "rgba(0,0,0,.7)",
	},
};

const FormSubmissions = () => {
	const [data, setData] = useState([]);
	const [form, setForm] = useState({});
	const [filtered, setFiltered] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [modal, setModal] = useState(false);
	const [modalPayment, setModalPayment] = useState(false);
	const [toExport, setToExport] = useState([]);
	const [statusToChange, setStatusToChange] = useState(null);
	const [message, setMessage] = useState("")
	const { id } = useParams();

	useEffect(() => {
		firebase
			.firestore()
			.collection("forms")
			.doc(id)
			.get()
			.then((res) => {
				const form = res.data()
				// console.log(form)
				setForm(form);
			});
		const subs = firebase
			.firestore()
			.collection("forms")
			.doc(id)
			.collection("submissions")
			.onSnapshot(
				(query) => {
					const d = query.docs.map((doc) => ({ ...doc.data(), id: doc.id, ref: doc.ref }));

					setData(d);
					setFiltered(d);
					setLoading(false);
				},
				(err) => {
					console.log(err);
					setError(true);
					setLoading(false);
				}
			);

		// copySubmissions(id, "nhglfoKP52wgnXiJYcLz")
		return () => subs();
	}, []);

	const copySubmissions = async (from, to) => {
		firebase.firestore().collection("forms").doc(from).collection("submissions").get().then(query => {
			query.docs.map(el => {
				firebase.firestore().collection("forms").doc(to).collection("submissions").doc(el.id).set(el.data())
			})

		})
	}

	const getDataToDownload = async () => {
		const a = data.map((el) => {
			const p = {
				...el,
				files: el?.files ? Object.values(el?.files)?.map(el => el?.uri).join("\n") : "",
				birthDate: moment(el.birthDate).format("DD/MM/YYYY")
			};


			delete p.ref;
			delete p.id;
			delete p.createdAt;
			delete p.lastUpdate;
			delete p.form;

			return p;
		});

		downloadXLSX(a);
	};

	const downloadXLSX = (dataToDownload) => {
		const ws = XLSX.utils.json_to_sheet(dataToDownload);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Datos");
		XLSX.writeFile(wb, form.name + "-" + moment().format("DD-MM-YYTHH-MM-A") + ".xlsx");
	};

	useEffect(() => {
		dataToExport(data);
	}, [data]);

	const deleteItem = async (submission) => {
		firebase
			.firestore()
			.collection("forms")
			.doc(id)
			.collection("submissions")
			.doc(submission)
			.delete()
			.then((res) => {
				alert("Se ha eliminado la entrada Correctamnete");
			})
			.catch((err) => {
				console.log(err);
				alert("Ha ocurrido un error al eliminar la entrada");
			});
	};

	const dataToExport = (arr) => {
		const a = arr.map((el) => {
			const p = { ...el };
			delete p.ref;
			delete p.id;
			delete p.createdAt;
			delete p.lastUpdate;
			delete p.form;
			delete p.files

			return p;
		});
		setToExport([...a]);
	};

	const changeStatus = (submission, prevStatus) => {
		firebase
			.firestore()
			.collection("forms")
			.doc(id)
			.collection("submissions")
			.doc(submission)
			.update({
				status: statusToChange,
				...(statusToChange === "canceled" && { message: message }),
				updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
			})
			.then((res) => {
				if (statusToChange === "payed") {
					firebase
						.firestore()
						.collection("forms")
						.doc(id)
						.update({
							spotsAvailable: firebase.firestore.FieldValue.increment(-1),
							updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
						})
						.then((res) => {
							setModalPayment(false);
							setMessage("")
							setStatusToChange(null);
						})
						.catch((err) => {
							alert("Ha ocurrido un error restando las plazas disponibles, por favor, realicelo manualmente");
						});
				} else {
					setModalPayment(false);
					setMessage("")
					setStatusToChange(null);
				}
			})
			.catch((err) => {
				alert("Ha ocurrido un error modificando esta solicitud, intentelo mas tarde");
			});
	};


	const download = async (url, name) => {

		firebase.storage().ref(url).getDownloadURL().then(url => {
			const extension = url.split("?")[0].split(".").pop();
			var xhr = new XMLHttpRequest();
			xhr.open("GET", url, true);
			xhr.responseType = "blob";
			xhr.onload = function () {
				var urlCreator = window.URL || window.webkitURL;
				var imageUrl = urlCreator.createObjectURL(this.response);
				var tag = document.createElement('a');
				tag.href = imageUrl;
				tag.download = `${name}.${extension}`;
				document.body.appendChild(tag);
				tag.click();
				document.body.removeChild(tag);
			}
			xhr.send();
		})
	}

	const columns = [
		{
			Header: "Nombre",
			accessor: "name",
			style: { display: "flex", alignItems: "center", padding: "10px 20px" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12, textAlign: "left", paddingLeft: 20 },
		},
		{
			Header: "Apellidos",
			accessor: "lastName",
			style: { display: "flex", alignItems: "center", padding: "10px 20px" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12, textAlign: "left", paddingLeft: 20 },
		},
		{
			Header: "Email",
			accessor: "email",
			style: { display: "flex", alignItems: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12, textAlign: "left", paddingLeft: 20 },
		},
		{
			Header: "Fecha",
			id: "date",
			style: { display: "flex", alignItems: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (row) => moment(row.createdAt?.toDate()).format("DD-MM-YYYY [a las] hh:mm"),
		},
		{
			Header: "Estado",
			id: "status",
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (row) =>
				row.status === "pendingValidation" ? (
					<div onClick={() => setModalPayment(row)} style={{ fontSize: 12, cursor: "pointer", color: "white", textAlign: "center", padding: "10px 20px", borderRadius: 6, backgroundColor: "#F5B041" }}>Pendiente Validaciorn</div>
				) : row.status === "pendingPayment" ? (
					<div onClick={() => setModalPayment(row)} style={{ fontSize: 12, cursor: "pointer", color: "white", textAlign: "center", padding: "10px 20px", borderRadius: 6, backgroundColor: "#F5B041" }}>Pendiente Pago</div>
				) : row.status === "payed" ? (
					<div onClick={() => setModalPayment(row)} style={{ fontSize: 12, cursor: "pointer", color: "white", textAlign: "center", padding: "10px 20px", borderRadius: 6, backgroundColor: "#58D68D" }}>Pagado</div>
				) : row.status === "pendingReview" ? (
					<div onClick={() => setModalPayment(row)} style={{ fontSize: 12, cursor: "pointer", color: "white", textAlign: "center", padding: "10px 20px", borderRadius: 6, backgroundColor: "#5DADE2" }}>Extracto Subido</div>
				) : (
					<div onClick={() => setModalPayment(row)} style={{ fontSize: 12, cursor: "pointer", color: "white", textAlign: "center", padding: "10px 20px", borderRadius: 6, backgroundColor: "#CB4335" }}>Cancelado</div>
				),
		},
		// {
		// 	Header: "Gestionar Pago",
		// 	id: "payment",
		// 	width: 120,
		// 	style: { display: "flex", justifyContent: "center" },
		// 	headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
		// 	accessor: (row) =>
		// 		row.files && (
		// 			<Button onClick={() => setModalPayment(row)}>
		// 				<IoCard size={20} />
		// 			</Button>
		// 		),
		// },
		{
			Header: "Link Pago",
			id: "paymentLink",
			width: 100,
			style: { display: "flex", justifyContent: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (row) => (
				(row.status === "pendingPayment") ? <Button onClick={() => window.open(`https://registro.nuevoboadilla.es/formulario/${id}/verificacion/${row.id}/`)}>
					<IoLink size={20} />
				</Button>
					:
					null
			),
		},
		{
			Header: "Detalles",
			id: "details",
			width: 100,
			style: { display: "flex", justifyContent: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (row) => (
				<Button onClick={() => setModal(row)}>
					<IoEye size={20} />
				</Button>
			),
		},
		{
			Header: "Eliminar",
			id: "delete",
			width: 100,
			style: { display: "flex", justifyContent: "center" },
			headerStyle: { paddingTop: 15, paddingBottom: 15, fontSize: 12 },
			accessor: (row) => (
				<Button
					onClick={() => {
						if (window.confirm("Estas seguro que deseas eliminar esta entrada. Esta acción no puede deshacerse una vez realizada y estos datos se perderán.")) {
							deleteItem(row.id);
						} else {
							console.log("NOT");
						}
					}}
				>
					<IoTrash size={20} />
				</Button>
			),
		},
	];

	if (loading) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<Loader type="Puff" color="#141b59" height={100} width={100} />
			</div>
		);
	}
	if (error) {
		return (
			<div style={{ display: "flex", flexGrow: 1, height: "calc(100% - 73px)", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<div style={{ padding: 20, marginTop: 15, maxWidth: 600, marginBottom: 15, backgroundColor: "#DFDFDF", borderRadius: 10, textAlign: "center" }}>
					<p style={{ margin: 0, fontSize: 18 }}>Ha ocurrido un error al cargar esta página, inténtelo de nuevo o contacte con email@email.com</p>
					<img src="https://pa1.narvii.com/6545/83dacee449b3e918df1b7c5c1c03176ae0878159_hq.gif" width={"auto"} height={200} style={{ margin: "0px auto" }} />
				</div>
			</div>
		);
	}
	return (
		<div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{`Administración ${data.name} - Club Deportivo Nuevo Boadilla `}</title>
			</Helmet>
			<div style={{ backgroundColor: "#f2f2f2", padding: "0px 20px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
				<h2>Entradas del formulario</h2>
				<div style={{ display: "flex", flex: 1, justifyContent: "flex-end", height: 50 }}>
					<SearchBox
						style={{ minWidth: 300 }}
						placeholder="Buscar..."
						onChange={(ev) =>
							setFiltered(
								data.filter(
									(el) =>
										el?.name?.toLocaleLowerCase()?.includes(ev.target.value.toLocaleLowerCase()) ||
										el?.email?.toLocaleLowerCase()?.includes(ev.target.value.toLocaleLowerCase()) ||
										el?.lastName?.toLocaleLowerCase()?.includes(ev.target.value.toLocaleLowerCase())
								)
							)
						}
					/>
					{/* <CSVLink data={toExport} style={{ textDecoration: "none" }} filename={"Exportar Datos de Curso-" + moment().format("DD-MM-YYTHH-MM-A") + ".csv"}>
					</CSVLink> */}
					<Button onClick={() => getDataToDownload()} style={{ fontSize: 16, fontFamily: "Montserrat", height: 50, marginLeft: 10, width: 150 }}> Exportar Datos</Button>
				</div>
			</div>
			<div style={{ padding: 15 }}>
				<ReactTable
					data={filtered}
					defaultPageSize={10}
					// loading={loading}
					loadingText="Cargando Datos..."
					previousText="Cursos Anteriores"
					nextText="Cursos Siguientes"
					noDataText="Sin Contenidos"
					pageText="Página"
					ofText="de"
					rowsText="filas"
					columns={columns}
				/>
			</div>

			<Modal ariaHideApp={false} isOpen={modal} style={customStyles} onRequestClose={() => setModal(false)} contentLabel="Example Modal">
				<h2 style={{ marginTop: 10, marginBottom: 10 }}>
					Detalles de {modal.name} {modal.lastName}
				</h2>
				<div style={{ padding: 15, overflowY: "auto", maxHeight: 600 }}>
					{form.fields.map((el, i) => {
						const index = Object.keys(modal).findIndex((f) => f === el.id);
						if (el.type === "title") {
							return (
								<h3>{el.name}</h3>
							)
						}
						if (el.type === "date") {
							return (
								<p key={i}>
									{el.name}: {moment(Object.values(modal)[index]).format("DD/MM/YYYY")}
									{/* {moment(Object.values(modal)[index]?.toDate())?.format("YYYY-MMM-DD")} */}
								</p>
							);
						} else {
							return (
								<p key={i}>
									{el.name}: {el.type === "checkbox" ? Object.values(modal)[index] === true ? "Si" : "No" : Object.values(modal)[index]}
								</p>
							);
						}
					})}
				</div>
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button style={{ width: 150 }} onClick={() => setModal(false)}>
						Cerrar
					</Button>
				</div>
			</Modal>

			<Modal ariaHideApp={false} isOpen={modalPayment} style={customStyles} onRequestClose={() => setModalPayment(false)} contentLabel="Modal Pagos">
				<h2 style={{ fontSize: "2em", marginTop: 10 }}>
					Detalles de pago de {modalPayment.name} {modalPayment.lastName}
				</h2>

				<div style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
					<label style={{ marginBottom: 10, color: "black" }}>Cambiar estado</label>
					<select
						onChange={(ev) => setStatusToChange(ev.target.value)}
						value={statusToChange}
						style={{
							height: 50,
							fontSize: 16,
							paddingLeft: 10,
							paddingRight: 10,
							fontFamily: "Montserrat",
							borderRadius: 5,
							border: "gainsboro solid 2px",
						}}
					// defaultValue={null}
					>
						<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value={"pendingValidation"}>
							Pendiente de Validar
						</option>
						<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value={"pendingPayment"}>
							Pendiente de Pago
						</option>
						<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value={"payed"}>
							Pagado
						</option>
						<option style={{ fontSize: 16, fontFamily: "Montserrat" }} value={"canceled"}>
							Cancelado
						</option>
					</select>
				</div>

				{statusToChange === "canceled" && <div style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
					<label style={{ marginBottom: 10, color: "black" }}>Motivo del rechazo</label>
					<textarea
						onChange={(ev) => setMessage(ev.target.value)}
						style={{
							height: 150, fontSize: 16,
							padding: 10,
							paddingLeft: 10,
							paddingRight: 10,
							fontFamily: "Montserrat",
							borderRadius: 5,
							border: "gainsboro solid 2px",
						}}
					/>
				</div>}

				{modalPayment.files && <div style={{ padding: 15, overflowY: "scroll", maxHeight: 600 }}>
					{Object.entries(modalPayment.files).map((el, i) => {
						return <button
							key={i}
							onClick={() => download(el[1]?.uri, `${modalPayment.name || ""}-${el[0] === "payment" ? "justificante-pago" : el[0] === "dniPlayer" ? "dni" : "libro-familia"}-${new Date().toDateString()}`)}
							style={{ backgroundColor: el[1] ? "#141b59" : "gainsboro", borderWidth:0, borderRadius: 5, paddingLeft: 10, paddingRight: 10, display: "flex", alignItems: 'center', alignSelf: "flex-start", cursor: "pointer", marginTop: 10, }}
						>
							<svg style={{ color: "white", width: 30, height: 30, marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
								<path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
							</svg>
							<p style={{ color: "white", fontSize: 12 }}>Ver Documento {el[0] === "payment" ? "de Justificante de Pago" : el[0] === "dniPlayer" ? "DNI del Jugador" : "Libro de Familia"}</p>
						</button>
					})}
					{/* <object data={modalPayment.files} width={700} height={"auto"} style={{ minHeight: 400 }} /> */}
				</div>}
				<div style={{ display: "flex", marginTop: 30, justifyContent: "space-between" }}>
					<Button style={{ width: 150, backgroundColor: "#839192" }} onClick={() => setModalPayment(false)}>
						Cerrar
					</Button>
					<Button style={{ width: 150 }} onClick={() => changeStatus(modalPayment.id, modalPayment.status)}>
						Editar Estado
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default FormSubmissions;

const Button = styled.button`
	height: 35px;
	width: 35px;
	background-color: #141b59;
	font-family: "Montserrat";
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0px;
	color: white;
	border-radius: 7px;
	align-self: center;
`;

const SearchBox = styled.input`
	font-size: 16px;
	padding-left: 20px;
	padding-right: 20px;
	font-family: "Montserrat";
`;



